<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>4. รายละเอียดเกี่ยวกับการละเมิดทางเพศ <small>(ไม่บังคับกรอก)</small> </div>
          <div>หมายเลขเอกสาร : {{ mainForm.oscc_code }}</div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          4.1 รายละเอียดการละเมิดทางเพศ
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label
                >4.1.1
                การใช้อวัยวะเพศของผู้กระทำสอดใส่กับอวัยวะของผู้เสียหาย</label
              >
                            <RadioGroup
                pattern="violence"
                :setData="formData.genital_insertion"
                @clickAnswer="formData.genital_insertion = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.genital_insertion" class="col-6">
              <label class="text-stroke mb-2"
                >(เลือกได้มากกว่า 1 ตัวเลือก)</label
              >
              <div v-for="(item, index) in part" :key="index">
                <div class="checkbox-border px-2 py-2 my-3">
                                  <Checkbox
                  pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>4.1.2 มีการใช้สิ่งอื่น กระทำกับอวัยวะของผู้เสียหาย</label>
                            <RadioGroup
                pattern="violence"
                :setData="formData.other_genital_insertion"
                @clickAnswer="formData.other_genital_insertion = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.other_genital_insertion" class="col-4">
              <label class="text-stroke mb-2">ระบุสิ่งอื่น</label>
              <input
                v-model="formData.other_genital_insertion_thing"
                :readonly="isAdmin"
                class="form-control mt-2"
                type="text"
              />
            </div>
            <div class="col-8"></div>
            <div v-if="formData.other_genital_insertion" class="col-6 mt-3">
              <label class="text-stroke mb-2"
                >(เลือกได้มากกว่า 1 ตัวเลือก)</label
              >
              <div v-for="(item, index) in otherPart" :key="index">
                <div class="checkbox-border px-2 py-2 my-3">
                                  <Checkbox
                  pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <label>4.1.3 การหลั่งอสุจิ</label>
                            <RadioGroup
                pattern="violence"
                :setData="formData.ejaculation"
                @clickAnswer="formData.ejaculation = $event"
                :choice="injection"
              ></RadioGroup>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label>4.1.4 การสวมถุงยาง</label>
                            <RadioGroup
                pattern="violence"
                :setData="formData.condom"
                @clickAnswer="formData.condom = $event"
                :choice="condom"
              ></RadioGroup>
            </div>
          </div>
          <div class="row">
            <div class="col-12">4.1.5 จำนวนผู้กระทำ</div>
            <div class="col-6 mt-2">
                            <RadioGroup
                pattern="violence"
                :setData="formData.know_doer_amount"
                @clickAnswer="formData.know_doer_amount = $event"
                :choice="victimAmout"
              ></RadioGroup>
            </div>
            <div
              v-if="formData.know_doer_amount !== 'ไม่ทราบ'"
              class="col-2 mt-3"
            >
              ระบุจำนวนคน
            </div>
            <div v-if="formData.know_doer_amount !== 'ไม่ทราบ'" class="col-3">
              <input
                v-model="formData.doer_amount"
                :readonly="isAdmin"
                class="form-control mt-2"
                type="number"
                min="0"
              />
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">4.2 การเก็บวัตถุพยาน</div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label
                >4.2.1 เสื้อผ้าที่สวมใส่อยู่ปัจจุบัน
                เป็นเสื้อผ้าที่สวมใส่ในช่วงเหตุการณ์</label
              >
                            <RadioGroup
                pattern="violence"
                :setData="formData.same_clothes_event"
                @clickAnswer="formData.same_clothes_event = $event"
                :choice="yesNo"
              ></RadioGroup>
            </div>
            <div class="col-3 text-stroke">ขออนุญาตส่งตรวจ</div>
            <div class="col-9 text-stroke">
              ถ้ายังไม่ได้ซักเสื้อผ้า แนะนำให้เก็บรักษา/ส่งตรวจภายหลัง
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <label
                >4.2.2 กางเกงในที่สวมใส่อยู่ในปัจจุบัน
                เป็นเสื้อผ้าที่สวมใส่ในช่วงเหตุการณ์</label
              >
                            <RadioGroup
                pattern="violence"
                :setData="formData.same_underwear_event"
                @clickAnswer="formData.same_underwear_event = $event"
                :choice="yesNo"
              ></RadioGroup>
            </div>
            <div class="col-3 text-stroke">ขออนุญาตส่งตรวจ</div>
            <div class="col-9 text-stroke">
              ถ้ายังไม่ได้ซักเสื้อผ้า แนะนำให้เก็บรักษา/ส่งตรวจภายหลัง
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <label
                >4.2.3 หลังจากเหตุการณ์
                ได้ทำความสะอาดร่างกายบริเวณที่ถูกกระทำชำเราหรือไม่</label
              >
                            <RadioGroup
                pattern="violence"
                :setData="formData.clean_body_after_event"
                @clickAnswer="formData.clean_body_after_event = $event"
                :choice="yesNo"
              ></RadioGroup>
            </div>
            <div
              v-if="formData.clean_body_after_event"
              class="col-12 text-stroke"
            >
              (เลือกได้มากกว่า 1 ตัวเลือก)
            </div>
            <div v-if="formData.clean_body_after_event" class="col-7">
              <div v-for="(item, index) in cleanBody" :key="index">
                <div class="checkbox-border px-2 py-2 my-3">
                                  <Checkbox
                  pattern="violence-checkbox"
                    @clickAnswer="setData($event)"
                    :setData="formData[item.key]"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <label
                >4.2.4 ภายใน 2 วัน หลังจากเกิดเหตุการณ์
                ได้มีเพศสัมพันธ์หรือไม่</label
              >
                            <RadioGroup
                pattern="violence"
                :setData="formData.have_sex_after_event"
                @clickAnswer="formData.have_sex_after_event = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button
          :disabled="isAdmin"
          @click="save(false)"
          class="btn btn-save-outline"
        >
          บันทึก
        </button>
      </div>
      <div class="col-3">
        <button
          :disabled="isAdmin"
          @click="save(true)"
          class="btn btn-next-outline"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import RadioGroup from "../RadioGroup.vue";
import Checkbox from "../Checkbox.vue";
import { mapState } from "vuex";
export default {
  components: {
    RadioGroup,
    Checkbox,
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  created() {
    this.formData.user_id = this.user ? this.user.id : "";
    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        if (this.$route.query.form) {
          this.formData.form_id = this.$route.query.form;
          await this.$store
            .dispatch("Violence/showForm3", this.$route.query.form)
            .then((response) => {
              if (response.main_form) {
                this.mainForm = response.main_form;
              }
              if (response.form) {
                this.formData = response.form;
              }
              let refer = response.refer;
              this.isAdmin = this.user ? this.user.role == 1 : false
              let isRefer = false;
              if (refer){
                if (this.user.agency_code == refer.from && refer.status == 0)isRefer = false;
                else if (this.user.agency_code == refer.from && refer.status == 1)isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 0)isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 1)isRefer = false;
              }
              if (!this.isAdmin)this.isAdmin = isRefer;
            });
        }
        Swal.clickConfirm();
      },
    });
  },
  methods: {
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    setData(model) {
      this.formData[model.name] = model.value;
    },
    save(nextPage) {
      Swal.fire({
        title: "กำลังบันทึกข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          this.formData.user_id = this.user ? this.user.id : "";
          if (this.formData.know_doer_amount !== "ไม่ทราบ") {
            if (!this.formData.doer_amount) {
              Swal.fire({
                icon: "error",
                title: "กรุณาระบุจำนวนผู้กระทำ",
                confirmButtonText: "ตกลง",
              });
              return;
            }
          }
          this.$store
            .dispatch("Violence/saveForm3", this.formData)
            .then((response) => {
              if (response.success) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                }).then(() => {
                  if (nextPage) {
                    Swal.close();
                    this.$router.push({
                      path: "violenec",
                      query: { page: 4, form: this.$route.query.form },
                    });
                  } else {
                    this.$router.push({
                      path: "violenec",
                      query: { page: 3, form: this.$route.query.form },
                    });
                    window.location.reload();
                    Swal.close();
                  }
                });
              } else {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "บันทึกไม่สำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                });
              }
            });
        },
      });
    },
  },
  data() {
    return {
      isAdmin : false,
      mainForm: {
        oscc_code: 0,
      },
      isBool: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      yesNo: [
        { name: "ใช่", value: true, grid: "col-3" },
        { name: "ไม่ใช่", value: false, grid: "col-3" },
      ],
      part: [
        { name: "อวัยวะเพศ", key: "victim_genitalia" },
        { name: "ทวารหนัก", key: "victim_anus" },
        { name: "ช่องปาก", key: "victim_mouth" },
      ],
      otherPart: [
        { name: "อวัยวะเพศ", key: "other_obj_victim_genitalia" },
        { name: "ทวารหนัก", key: "other_obj_victim_anus" },
        { name: "ช่องปาก", key: "other_obj_victim_mouth" },
      ],
      injection: [
        { name: "มี", grid: "col-12" },
        { name: "ไม่มี", grid: "col-12" },
        { name: "ไม่ทราบ", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่ามี", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่าไม่มี", grid: "col-12" },
      ],
      condom: [
        { name: "สวม", grid: "col-12" },
        { name: "ไม่สวม", grid: "col-12" },
        { name: "ไม่ทราบ", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่าสวม", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่าไม่สวม", grid: "col-12" },
      ],
      victimAmout: [
        { name: "รู้จำนวนที่แน่นอน", grid: "col-12" },
        { name: "ไม่ค่อยแน่ใจ แต่ค่อนข้างเชื่อว่ามี", grid: "col-12" },
        { name: "ไม่ทราบ", grid: "col-12" },
      ],
      cleanBody: [
        {
          name: "ล้างภายนอก / สวนล้าง (อวัยวะเพศ / ทวารหนัก)",
          key: "genitalia_anus_cleaning",
        },
        { name: "ถ่ายอุจาระ", key: "defecate" },
        { name: "บ้วนปาก / แปรงฟัน", key: "oral_cleaning" },
      ],
      formData: {
        form_id: "",
        user_id: "",
        genital_insertion: false,
        victim_genitalia: false,
        victim_anus: false,
        victim_mouth: false,
        other_genital_insertion: false,
        other_genital_insertion_thing: "",
        other_obj_victim: false,
        other_obj_victim_genitalia: false,
        other_obj_victim_anus: false,
        other_obj_victim_mouth: false,
        ejaculation: "ไม่มี",
        has_ejaculation: false,
        not_has_ejaculation: false,
        not_know_ejaculation: false,
        not_sure_but_yes_ejaculation: false,
        not_sure_but_no_ejaculation: false,
        condom: "ไม่สวม",
        no_condom: false,
        not_know_condom: false,
        not_sure_but_yes_condom: false,
        not_sure_but_no_condom: false,
        know_doer_amount: "ไม่ทราบ",
        doer_amount: false,
        not_know_doer_amount: false,
        not_sure_but_yes_doer_amount: false,
        same_clothes_event: false,
        same_underwear_event: false,
        clean_body_after_event: false,
        genitalia_anus_cleaning: false,
        defecate: false,
        oral_cleaning: false,
        have_sex_after_event: false,
      },
    };
  },
};
</script>

<style>
</style>
