<template>
  <div class="mt-5">
    <h2 class="text-center">แบบบันทึกการลงข้อมูลผู้ที่ถูกกระทำรุนแรง</h2>
    <div class="container">
        <b-button-group class="btn-block bg-grey-theme">
          <b-button
            :disabled="item.id === 1 ? false : isDisable"
            @click="clickPage(item)"
            v-for="item in buttonList"
            :key="item.id"
            :class="
              item.isActive
                ? 'btn-page-violence px-5'
                : 'btn-no-page-violence px-5'
            "
            >{{ item.name }}</b-button
          >
        </b-button-group>
    </div>
    <div class="container my-5" style="max-width: 1200px">
      <Form1 v-if="currentPage === 1"></Form1>
      <Form2 v-if="currentPage === 2"></Form2>
      <Form3 v-if="currentPage === 3"></Form3>
      <Form4 v-if="currentPage === 4"></Form4>
      <Form5 v-if="currentPage === 5"></Form5>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import Form1 from "../components/violence/Form1.vue";
import Form2 from "../components/violence/Form2.vue";
import Form3 from "../components/violence/Form3.vue";
import Form4 from "../components/violence/Form4.vue";
import Form5 from "../components/violence/Form5.vue";
export default {
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  components: {
    Form1,
    Form2,
    Form3,
    Form4,
    Form5,
  },
  data() {
    return {
      isDisable: false,
      buttonList: [
        { id: 1, name: "ส่วนที่ 1", isActive: true, isDisable: false },
        { id: 2, name: "ส่วนที่ 2", isActive: false, isDisable: false },
        { id: 3, name: "ส่วนที่ 3", isActive: false, isDisable: false },
        { id: 4, name: "ส่วนที่ 4", isActive: false, isDisable: false },
        { id: 5, name: "ส่วนที่ 5", isActive: false, isDisable: false },
      ],
      currentPage: 1,
    };
  },
  created() {
    if (this.user) {
      let page = this.$route.query.page ? this.$route.query.page : 1;
      const formId = this.$route.query.form;
      if (!formId && page >= 1) {
        page = 1;
        this.$router.push({
          path: "violenec",
          query: { page: page},
        });
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }
      this.currentPage = parseInt(page);

      // const formId = this.$route.query.form;
      // if(formId){

      // }

      this.buttonList.forEach((x) => {
        if (x.id === this.currentPage) x.isActive = true;
        else x.isActive = false;
      });
    } else {
      this.$router.push({
        path: "result",
      });
      Swal.fire({
        icon: "error",
        title: "กรุณาลงชื่อเข้าใช้ก่อนกรอกข้อมูล",
        confirmButtonText: "ตกลง",
      });
    }
  },
  methods: {
    clickPage(model) {
      this.$router.push({
        path: "violenec",
        query: { page: model.id, form: this.$route.query.form },
      });
    },
  },
};
</script>

<style>
</style>