<template>
    <div class="modal-oscc">

        <div class="modal-header-oscc pl-3">
            <label>ยืนยัน การลบ</label>
        </div>

        <div class="modal-button-oscc">
            <b-button class="button-oscc" variant="success" @click="submit">ยืนยัน</b-button>
            <b-button class="button-oscc float-right" variant="danger" @click="cancel">ยกเลิก</b-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "comfirm-delete-modal"
        ,
        data: () => ({

        }),
        methods:{
            submit(){
                this.$emit("submit", true);
            },
            cancel(){
                this.$emit("cancel", false);
            },
        }
    }
</script>

<style scoped>
    .modal-oscc{
        font-family: Prompt;
        border-radius: 10px;
        padding-top: 3%;
    }
    .modal-header-oscc{
        font-size: 24px;
        border-bottom: 1px solid black;
        width: 100%;
    }
    .modal-button-oscc{
        padding: 3% 20% 3% 20%;
    }
    .button-oscc{
        font-size: 16px;
        border-radius: 80px;
        padding: 7px 20px 7px 20px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
    }
</style>
