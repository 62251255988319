<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>5. การประเมินสภาพปัญหาของผู้รับบริการ</div>
          <div>หมายเลขเอกสาร : {{ oscc_code }}</div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          ระบุตำแหน่งและลักษณะบาดแผล
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <b-form-group>
                <div class="row">
                  <div class="mt-3 text-left col-3">
                    <div class="btn btn-purple-4-outline" style="width: 100%">
                      <b-form-radio
                        v-model="selected_sex"
                        name="some-radios"
                        value="หญิง"
                        >หญิง</b-form-radio
                      >
                    </div>
                  </div>
                  <div class="mt-3 text-left col-3">
                    <div class="btn btn-purple-4-outline" style="width: 100%">
                      <b-form-radio
                        v-model="selected_sex"
                        name="some-radios"
                        value="ชาย"
                        >ชาย</b-form-radio
                      >
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row text-center">
            <div class="col-12">
              <img
                v-if="selected_sex == 'ชาย'"
                :src="mAnatomy"
                alt="anatomy"
                style="position: relative"
              />
              <img
                v-else-if="selected_sex == 'หญิง'"
                :src="fAnatomy"
                alt="anatomy"
                style="position: relative"
              />
              <table id="cell1000" cellspacing="0">
                <tr v-for="(row, y) in tableCell" v-bind:key="y">
                  <td
                    v-for="(value, x) in row"
                    class="cellDraw"
                    v-bind:key="x"
                    v-on:click="editPoint(x, y, value)"
                  >
                    <div
                      v-if="tableCell[y][x].active"
                      v-bind:class="[
                        tableCell[y][x].active ? 'activeCell' : '',
                        'divCell',
                      ]"
                    >
                      {{ tableCell[y][x].index }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          ตารางรายการข้อมูลตำแหน่ง
        </div>
        <div class="card-body">
          <table class="table">
            <thead class="bg-purple-3-theme">
              <tr class="text-center">
                <th scope="col">ลำดับ</th>
                <th scope="col">ตำแหน่งของแผล</th>
                <th scope="col">รูปบาดแผล</th>
                <th scope="col">ลบ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(val, i) in this.injury" v-bind:key="i">
                <th scope="row" class="text-center">
                  {{ i + 1 }}
                </th>
                <td>{{ val.injury_name }}</td>
                <td class="text-center">
                  <img v-img :src="val.injury_image" width="auto" height="50" />
                </td>
                <td class="text-center">
                  <b-button variant="danger" @click="showConfirm(i)"
                    >ลบ</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button :disabled="isAdmin" @click="save(false)" class="btn btn-save-outline">
          บันทึก
        </button>
      </div>
      <div class="col-3">
        <button :disabled="isAdmin" @click="save(true)" class="btn btn-next-outline">ถัดไป</button>
      </div>
    </div>
    <b-modal
      hide-footer
      centered
      size="md"
      id="injury-modal"
      ref="injury_modal"
      body-class="p-0"
      title="กรุณากรอกรายละเอียดตำแหน่งและลักษณะบาดแผล"
    >
      <injury-modal
        @submit="addInjury"
        @cancel="$bvModal.hide('injury-modal')"
      ></injury-modal>
    </b-modal>
    <b-modal
      hide-header
      hide-footer
      body-class="p-0"
      id="confirm-modal"
      ref="confirm_modal"
    >
      <comfirm-delete-modal
        @submit="deleteList"
        @cancel="$bvModal.hide('confirm-modal')"
      ></comfirm-delete-modal>
    </b-modal>
  </div>
</template>

<script>
import fAnatomy from "../../assets/img/female-anatomy.png";
import mAnatomy from "../../assets/img/male-anatomy.png";
// import RadioGroup from "../RadioGroup.vue";
import InjuryModal from "../Modal/injuryModal";
import Swal from "sweetalert2";
import { mapState } from "vuex";
import ComfirmDeleteModal from "../Modal/comfirmDeleteModal";
export default {
  components: {
    ComfirmDeleteModal,
    InjuryModal,
  },
  data() {
    return {
      items: [{ age: 40, first_name: "Dickerson", last_name: "Macdonald" }],
      fAnatomy: fAnatomy,
      mAnatomy: mAnatomy,
      sex: [
        { name: "หญิง", grid: "col-3" },
        { name: "ชาย", grid: "col-3" },
      ],
      tableCell: null,
      selected: {
        index: "",
        y: "",
        x: "",
      },
      isAdmin : false,
      selected_delete: "",
      formData: {
        form_id: "",
        user_id: "",
        sex: "",
        order: "",
        wound_position: [],
        wound_image: "",
      },
    };
  },
  computed: {
    ...mapState({
      injury: (state) => {
        return state.Violence.injury_form4;
      },
      oscc_code: (state) => {
        return state.Violence.oscc_code;
      },
      selected_sex: (state) => {
        return state.Violence.selected_sex;
      },
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  created() {
    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        if (this.$route.query.form) {
          await this.$store
            .dispatch("Violence/showForm4", this.$route.query.form)
            .then(res => {
              this.rePoint()
              let refer = res.data.refer;
              this.isAdmin = this.user ? this.user.role == 1 : false
              let isRefer = false;
              if (refer){
                if (this.user.agency_code == refer.from && refer.status == 0)isRefer = false;
                else if (this.user.agency_code == refer.from && refer.status == 1)isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 0)isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 1)isRefer = false;
              }
              if (!this.isAdmin)this.isAdmin = isRefer;
            });
        } else {
          await this.$store
            .dispatch("Violence/clearList")
            .then(() => this.rePoint());
        }
        Swal.clickConfirm();
      },
    });
  },
  mounted() {
    this.tableCell = this.newTable(50, 40);
    this.rePoint();
  },
  methods: {
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    save(nextPage) {
      // this.formdata = {
      //   form_id : '',
      //   user_id : '',
      //   sex : '',
      //   order : '',
      //   wound_position : [],
      //   wound_image : '',
      // }
      // console.log(data)

      Swal.fire({
        title: "กำลังบันทึกข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          this.$store
            .dispatch("Violence/saveForm4", this.injury)
            .then((response) => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                }).then(() => {
                  this.formData = response.data;
                  if (nextPage) {
                    this.$router.push({
                      path: "violenec",
                      query: { page: 5, form: this.$route.query.form },
                    });
                  }
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "บันทึกไม่สำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                });
              }
            });
        },
      });
    },
    addInjury(val) {
      let injuryOBJ = {
        form_id: this.$route.query.form,
        user_id: this.user ? this.user.id : "1",
        injury_name: val.injury_name,
        sex: this.selected_sex,
        injury_image: val.injury_image,
        index: this.selected.index,
        x: this.selected.x,
        y: this.selected.y,
      };
      this.$store.dispatch("Violence/addList", injuryOBJ);
      this.tableCell[this.selected.y][this.selected.x].active = 1;
      this.tableCell[this.selected.y][this.selected.x].index =
        this.injury.length;
      this.$bvModal.hide("injury-modal");
    },
    editPoint(x, y) {
      this.selected.index = this.injury.length + 1;
      this.selected.y = y;
      this.selected.x = x;
      this.$refs.injury_modal.show();
    },
    showConfirm(val) {
      this.selected_delete = val;
      Swal.fire({
        title: "คุณแน่ใจว่าจะลบ ?",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteList(true);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "ลบรายการสำเร็จ",
            showConfirmButton: false,
            timer: 700,
            width: 200,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "ยกเลิก",
            showConfirmButton: false,
            timer: 700,
            width: 200,
          });
        }
      });
    },
    deleteList(val) {
      if (val) {
        this.$store.dispatch("Violence/deleteList", this.selected_delete);
        this.tableCell = this.newTable(50, 40);
        this.rePoint();
      }
    },
    rePoint() {
      if (this.injury.length > 0) {
        this.selected_sex = this.injury[0].sex;
        this.injury.forEach((data, i) => {
          this.tableCell[data.y][data.x].active = 1;
          this.tableCell[data.y][data.x].index = i + 1;
        });
      } else {
        this.tableCell = this.newTable(50, 40);
      }
    },
    newTable(w, h) {
      let arr = [];
      for (let y = 0; y < h; y++) {
        arr[y] = [];
        for (let x = 0; x < w; x++) {
          let injuryOBJ = {
            injury_name: "",
            active: "",
            injury_image: "",
            index: "",
            x: x,
            y: y,
          };
          arr[y][x] = injuryOBJ;
        }
      }
      return arr;
    },
  },
};
</script>

<style scoped>
#cell1000 {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.divCell {
  font-size: 5px;
  line-height: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.activeCell {
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #73ad21;
  font-weight: bold;
  color: white;
  background-color: #6368ec;
  margin: -5px;
}
.cellDraw {
  width: 1%;
  height: 1%;
  position: relative;
  padding: 0;
  margin: 0;
}
</style>
