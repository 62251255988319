<template>
    <div class="modal-oscc">

        <label class="float-left pl-5 pr-5">ตำแหน่งของแผล</label>
        <input v-model="injury.injury_name" class="form-control mt-2 ml-5" style="width: 80%" type="text" />

        <label class="float-left mt-3 pl-5 pr-5">รูปบาดแผล</label>
        <b-input-group class="input-group-form-oscc mb-5 ml-5" style="width: 80%">
            <b-form-input v-model="injury.injury_message" class="input-form-oscc" readonly></b-form-input>
            <template #append>
                <b-input-group-text class="input-prepend-oscc" style="cursor:pointer;" @click="chooseImage()">
                    <b-img width="20%" height="20%" :src="require('@/assets/icon/upload_injury.svg')"></b-img>
                </b-input-group-text>
            </template>
        </b-input-group>
        <b-form-file id="upload-image"
                     style="display: none"
                     @change="uploadImage"
                     size="lg">
        </b-form-file>

        <div slot="modal-footer" class="w-100 modal-footer-oscc py-3 px-5">
            <b-button class="button-oscc float-right" variant="primary" @click="submit()">บันทึก</b-button>
            <b-button class="button-oscc float-right mb-2 mr-4" variant="light" @click="cancel()">ยกเลิก</b-button>
        </div>

    </div>
</template>

<script>
    export default {
        name: "injury-modal"
        ,
        data: () => ({
            injury : {
                injury_name : '',
                injury_message : '',
                injury_image : '',
            }
        }),
        methods:{
            submit(){
                this.$emit("submit", this.injury);
            },
            cancel(){
                this.$emit("cancel", false);
            },
            chooseImage(){
                document.getElementById('upload-image').click();
            },
            uploadImage:function(event){
                let input = event.target;
                let file = input.files[0];
                if(file){
                    let formData = new FormData();
                    formData.append('file',file);
                    formData.append('folder','injury');
                    this.$store.dispatch("Auth/getPathFile",formData).then(path_file=>{
                        this.injury.injury_image = path_file.data;
                        this.injury.injury_message = path_file.message;
                    })
                }
            },
        }
    }
</script>

<style scoped>
    input[readonly]{
        background-color:transparent;
        border: 0;
    }
    .input-form-oscc{
        border: transparent !important;
    }
    .input-form-oscc:focus {
        outline: none !important;
    }
    .input-group-form-oscc{
        background: #FFFFFF;
        border: 1px solid #4579DF;
        box-sizing: border-box;
        border-radius: 80px;
    }
    .input-prepend-oscc{
        background: transparent;
        border: transparent;
        margin-top: 2px;
    }
    .modal-oscc{
        font-family: Prompt;
        border-radius: 10px;
        text-align: center;
        padding-top: 3%;
    }
    .modal-footer-oscc{
        border-top: 1px solid black;
    }
    .button-oscc{
        font-size: 16px;
        border-radius: 80px;
        padding: 7px 20px 7px 20px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
    }
</style>
