<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>6. บริการทางการแพทย์และสังคม</div>
          <div>หมายเลขเอกสาร : {{ mainForm.oscc_code }}</div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          6.1 ด้านการแพทย์ (เลือกได้มากกว่า 1 ตัวเลือก)
        </div>
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['medical_diagnosis']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การตรวจประเมินและวินิจฉัยทางการแพทย์',
                    key: 'medical_diagnosis',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div v-if="formData.medical_diagnosis" class="col-12">
              <div class="row">
                <div class="offset-2 col-5">
                  <div class="checkbox-border px-2 py-2 my-3">
                    <Checkbox
                      pattern="violence-checkbox"
                      :setData="formData['medical_diagnosis_violence']"
                      :isSingle="true"
                      @clickAnswer="setData($event)"
                      :model="{
                        name: 'การตรวจลักษณะการบาดเจ็บกรณีถูกทำร้ายร่างกาย',
                        key: 'medical_diagnosis_violence',
                      }"
                    ></Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="formData.medical_diagnosis" class="col-12">
              <div class="row">
                <div class="offset-2 col-5">
                  <div class="checkbox-border px-2 py-2 my-3">
                    <Checkbox
                      pattern="violence-checkbox"
                      :setData="formData['medical_diagnosis_obstetrics']"
                      :isSingle="true"
                      @clickAnswer="setData($event)"
                      :model="{
                        name: 'การตรวจรักษาทางสูตินารีเวชกรรม',
                        key: 'medical_diagnosis_obstetrics',
                      }"
                    ></Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="formData.medical_diagnosis" class="col-12">
              <div class="row">
                <div class="offset-2 col-5">
                  <div class="checkbox-border px-2 py-2 my-3">
                    <Checkbox
                      pattern="violence-checkbox"
                      :setData="formData['medical_diagnosis_lab']"
                      :isSingle="true"
                      @clickAnswer="setData($event)"
                      :model="{
                        name: 'การตรวจทางห้องปฏิบัติการ (LAB) ',
                        key: 'medical_diagnosis_lab',
                      }"
                    ></Checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['pathology_diagnosis']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การตรวจวินิจฉัยทางพยาธิวิทยาคลินิก',
                    key: 'pathology_diagnosis',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.pathology_diagnosis" class="col-8"></div>
            <div v-if="formData.pathology_diagnosis" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['development_diagnosis']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การประเมินพัฒนาการ',
                    key: 'development_diagnosis',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-6"></div>
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['cure_procedure']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การรักษาและหัตถการ',
                    key: 'cure_procedure',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['cure_injury']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การดูแลรักษาการบาดเจ็บ',
                    key: 'cure_injury',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['medicine_birth_control']"
                  :isSingle="true"
                  :model="{
                    name: 'การได้รับยาป้องกันการตั้งครรภ์',
                    key: 'medicine_birth_control',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['medicine_hiv']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การได้รับยาป้องกันไวรัส เอชไอวี',
                    key: 'medicine_hiv',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['medicine_sex_infection']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การได้รับยาป้องกันการติดเชื้อทางเพศสัมพันธ์',
                    key: 'medicine_sex_infection',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['antenatal_care']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การฝากครรภ์ต่อ',
                    key: 'antenatal_care',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['abortion']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การยุติการตั้งครรภ์',
                    key: 'abortion',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div v-if="formData.cure_procedure" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['birth_control']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การคุมกำเนิด',
                    key: 'birth_control',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.cure_procedure" class="col-6"></div>
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['medical_recovery']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การบำบัดฟื้นฟูทางการแพทย์',
                    key: 'medical_recovery',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['follow_up_treatment']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การติดตามการรักษา',
                    key: 'follow_up_treatment',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['send_to_specialist']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การส่งพบแพทย์เฉพาะทาง',
                    key: 'send_to_specialist',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['autopsy_opinion']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การลงความเห็นในใบชันสูตร',
                    key: 'autopsy_opinion',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div class="col-4">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['estimate_age_result']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'การตรวจประเมินอายุ*+ผลการประเมินอายุ..ปี',
                    key: 'estimate_age_result',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div class="col-8"></div>
            <div v-if="formData.estimate_age_result" class="offset-2 col-2">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['lowest_age']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'อายุน้อยสุด',
                    key: 'lowest_age',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.estimate_age_result" class="col-2">
              <input
                :readonly="isAdmin"
                v-model="formData.lowest_age_year"
                class="form-control mt-2"
                type="number"
                min="0"
              />
            </div>
            <div v-if="formData.estimate_age_result" class="col-1">ปี</div>
            <div v-if="formData.estimate_age_result" class="col-2">
              <input
                :readonly="isAdmin"
                v-model="formData.lowest_age_month"
                class="form-control mt-2"
                type="number"
                min="0"
              />
            </div>
            <div v-if="formData.estimate_age_result" class="col-1">เดือน</div>

            <div v-if="formData.estimate_age_result" class="offset-2 col-2">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['most_age']"
                  :isSingle="true"
                  @clickAnswer="setData($event)"
                  :model="{
                    name: 'อายุมากสุด',
                    key: 'most_age',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.estimate_age_result" class="col-2">
              <input
                :readonly="isAdmin"
                v-model="formData.most_age_year"
                class="form-control mt-2"
                type="number"
                min="0"
              />
            </div>
            <div v-if="formData.estimate_age_result" class="col-1">ปี</div>
            <div v-if="formData.estimate_age_result" class="col-2">
              <input
                :readonly="isAdmin"
                v-model="formData.most_age_month"
                class="form-control mt-2"
                type="number"
                min="0"
              />
            </div>
            <div v-if="formData.estimate_age_result" class="col-1">เดือน</div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          6.2 ด้านสุขภาพจิตและจิตเวช (เลือกได้มากกว่า 1 ตัวเลือก)
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[therapy[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="therapy[0]"
                ></Checkbox>
              </div>
            </div>
            <div class="col-6"></div>
            <div v-if="formData.assessment_diagnosis" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['ptsd_test']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'ประเมิน PTSD',
                    key: 'ptsd_test',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.assessment_diagnosis" class="col-4"></div>
            <div v-if="formData.assessment_diagnosis" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['psycho_test']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'Psycho test',
                    key: 'psycho_test',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.assessment_diagnosis" class="col-4"></div>
            <div v-if="formData.assessment_diagnosis" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['iq_test']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'IQ Test',
                    key: 'iq_test',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.assessment_diagnosis" class="col-4"></div>
            <div v-if="formData.assessment_diagnosis" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['consulting_mental_health']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'การให้คำปรึกษาและสุขภาพจิตศึกษา',
                    key: 'consulting_mental_health',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.assessment_diagnosis" class="col-4"></div>
            <!-- <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[therapy[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="therapy[1]"
                ></Checkbox>
              </div>
            </div> -->

            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[therapy[2].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="therapy[2]"
                ></Checkbox>
              </div>
            </div>
            <div class="col-6"></div>
            <div v-if="formData.treatment" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['counseling']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'Counseling',
                    key: 'counseling',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.treatment" class="col-4"></div>
            <div v-if="formData.treatment" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['psycho_therapy']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'Psycho Therapy',
                    key: 'psycho_therapy',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.treatment" class="col-4"></div>
            <div v-if="formData.treatment" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['family_therapy']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'Family Therapy',
                    key: 'family_therapy',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.treatment" class="col-4"></div>
            <div v-if="formData.treatment" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['cbt_therapy']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'บำบัดความคิดและพฤติกรรม (CBT)',
                    key: 'cbt_therapy',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.treatment" class="col-4"></div>
            <div v-if="formData.treatment" class="offset-2 col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData['medicine_tharapy']"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="{
                    name: 'บำบัดรักษาด้วยยา',
                    key: 'medicine_tharapy',
                  }"
                ></Checkbox>
              </div>
            </div>
            <div v-if="formData.treatment" class="col-4"></div>
            <div class="col-6">
              <div class="checkbox-border px-2 py-2 my-3">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[therapy[3].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="therapy[3]"
                ></Checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          6.3 ด้านสังคม (เลือกได้มากกว่า 1 ตัวเลือก)
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-7">
              <div v-for="(item, index) in social" :key="index">
                <div class="checkbox-border px-2 py-2 my-3">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>7. การให้รหัสมาตรฐานทางการแพทย์และรหัสหัตถการ</div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3 required">
          7.1 Principle Diagnosis
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <input
                :readonly="isAdmin"
                @change="getDiagnosis($event)"
                v-model="priciple_diagnosis.code"
                class="form-control mt-2"
                type="text"
              />
              <small v-if="!priciple_diagnosis.code" class="text-danger"
                >กรุณาระบุรหัส</small
              >
            </div>
            <div class="col-4">
              <input
                v-model="priciple_diagnosis.descriptiopn"
                readonly
                class="form-control mt-2"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">7.2 Comobidity</div>
        <div class="card-body">
          <RadioGroup
            pattern="violence"
            :setData="formData.is_comorbidity"
            @clickAnswer="formData.is_comorbidity = $event"
            :choice="isBool"
          ></RadioGroup>
          <div v-if="formData.is_comorbidity">
            <div
              v-for="(item, index) in comobidityList"
              :key="index"
              class="row"
            >
              <div class="col-3">
                <input
                  :readonly="isAdmin"
                  @change="getComobidity(index)"
                  v-model="item.code"
                  class="form-control mt-2"
                  type="text"
                />
              </div>
              <div class="col-4">
                <input
                  readonly
                  v-model="item.descriptiopn"
                  class="form-control mt-2"
                  type="text"
                />
              </div>
              <div class="col-1">
                <button
                  @click="removeComobidity(index)"
                  class="btn btn-outline-dark btn-circle"
                >
                  <i class="bi bi-dash-lg"></i>
                </button>
              </div>
              <div class="col-1">
                <button
                  @click="addComobidity"
                  class="btn btn-outline-dark btn-circle"
                >
                  <i class="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">7.3 Complication</div>
        <div class="card-body">
          <RadioGroup
            pattern="violence"
            :setData="formData.is_complication"
            @clickAnswer="formData.is_complication = $event"
            :choice="isBool"
          ></RadioGroup>
          <div v-if="formData.is_complication">
            <div
              v-for="(item, index) in complicationList"
              :key="index"
              class="row"
            >
              <div class="col-3">
                <input
                  :readonly="isAdmin"
                  @change="getComplication(index)"
                  v-model="item.code"
                  class="form-control mt-2"
                  type="text"
                />
              </div>
              <div class="col-4">
                <input
                  readonly
                  v-model="item.descriptiopn"
                  class="form-control mt-2"
                  type="text"
                />
              </div>
              <div class="col-1">
                <button
                  @click="removeComplication(index)"
                  class="btn btn-outline-dark btn-circle"
                >
                  <i class="bi bi-dash-lg"></i>
                </button>
              </div>
              <div class="col-1">
                <button
                  @click="addComplication"
                  class="btn btn-outline-dark btn-circle"
                >
                  <i class="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">7.4 Operation</div>
        <div class="card-body">
          <RadioGroup
            pattern="violence"
            :setData="formData.is_operation"
            @clickAnswer="formData.is_operation = $event"
            :choice="isBool"
          ></RadioGroup>
          <div v-if="formData.is_operation">
            <div
              v-for="(item, index) in operationList"
              :key="index"
              class="row"
            >
              <div class="col-3">
                <input
                  :readonly="isAdmin"
                  @change="getOperation(index)"
                  v-model="item.code"
                  class="form-control mt-2"
                  type="text"
                />
              </div>
              <div class="col-4">
                <input
                  readonly
                  v-model="item.descriptiopn"
                  class="form-control mt-2"
                  type="text"
                />
              </div>
              <div class="col-1">
                <button
                  @click="removeOperation(index)"
                  class="btn btn-outline-dark btn-circle"
                >
                  <i class="bi bi-dash-lg"></i>
                </button>
              </div>
              <div class="col-1">
                <button
                  @click="addOperation"
                  class="btn btn-outline-dark btn-circle"
                >
                  <i class="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>8. การดำเนินการทางกฏหมาย</div>
        </div>
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-12">
              <RadioGroup
                pattern="violence"
                :setData="formData.law_operation"
                @clickAnswer="formData.law_operation = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.law_operation" class="col-2">แจ้งความที่</div>
            <div v-if="formData.law_operation" class="col-4">
              <input
                :readonly="isAdmin"
                v-model="formData.law_operation_place"
                class="form-control mt-2"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>9. สถานะการให้บริการ</div>
        </div>
        <div class="card-body">
          <div class="row align-items-end">
            <div class="col-4">
              <RadioGroup
                pattern="violence"
                :setData="formData.service_status"
                @clickAnswer="formData.service_status = $event"
                :choice="service"
              ></RadioGroup>
            </div>
            <div class="col-6"></div>
            <div v-if="!formData.service_status" class="offset-2 col-4">
              <RadioGroup
                pattern="violence"
                :setData="formData.service_status_denied"
                @clickAnswer="formData.service_status_denied = $event"
                :choice="subService"
              ></RadioGroup>
            </div>
            <div
              v-if="
                formData.service_status_denied ===
                'ส่งต่อไปรักษายังหน่วยงานอื่น ๆ'
              "
              class="col-3"
            >
              <small>รหัสหน่วยงาน (5 หลัก)</small>
              <v-autocomplete
                v-model="model"
                dense
                @keyup="searchHosp(search, 'hospcode')"
                @change="onSelect(model)"
                :readonly="isAdmin"
                :items="hospitalList"
                item-text="hospcode"
                item-value="hospcode"
                :loading="isLoading"
                :search-input.sync="search"
                rounded
                clearable
                solo
                return-object
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.hospcode }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.hospcode }} - {{ data.item.name }}
                </template>
              </v-autocomplete>
            </div>
            <div
              v-if="
                formData.service_status_denied ===
                'ส่งต่อไปรักษายังหน่วยงานอื่น ๆ'
              "
              class="col-3"
            >
              <small>ชื่อหน่วยงาน</small>
              <v-autocomplete
                v-model="hspModel"
                dense
                @keyup="searchHosp(hspSearch, 'name')"
                @change="onSelect(hspModel)"
                :readonly="isAdmin"
                :items="hospitalList"
                item-text="name"
                item-value="name"
                :loading="isLoading"
                :search-input.sync="hspSearch"
                rounded
                clearable
                solo
                return-object
              >
              </v-autocomplete>
              <!-- <input
                v-model="formData.hosp_name"
                readonly
                type="text"
                class="form-control"
              /> -->
            </div>
          </div>
          <div
            v-if="
              formData.service_status_denied ===
              'ส่งต่อไปรักษายังหน่วยงานอื่น ๆ'
            "
            class="row align-items-center"
          >
            <div class="offset-2 col-6">
              <FileList
                @removeFile="forwardFileRemove()"
                @uploadFile="forwardFileUpload($event)"
                :setData="forwardFile"
                name="forward"
              ></FileList>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button
          :disabled="isAdmin"
          @click="gotoPregnant"
          class="btn btn-save-outline"
        >
          เพิ่มเคสตั้งครรภ์ไม่พึงประสงค์
        </button>
      </div>
      <div class="col-3">
        <button
          :disabled="isAdmin"
          @click="save(false)"
          class="btn btn-save-outline"
        >
          บันทึก
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import RadioGroup from "../RadioGroup.vue";
import Checkbox from "../Checkbox.vue";
import FileList from "../FileList.vue";
import Service from "../../services/index";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  components: {
    RadioGroup,
    Checkbox,
    FileList,
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  created() {
    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        if (this.$route.query.form) {
          this.formData.form_id = this.$route.query.form;
          await this.$store
            .dispatch("Violence/showForm5", this.$route.query.form)
            .then((response) => {
              if (response.main_form) {
                this.mainForm = response.main_form;
              }
              if (response.form) {
                if (response.form.hospcode) {
                  this.model = response.form.hospcode;
                  this.hspModel = response.form.hospcode;
                  this.hospitalList.push(response.form.hospcode);
                }
                delete response.form.hospcode;
                this.formData = response.form;
                this.forwardFile = JSON.parse(this.formData.forward_file);
                this.priciple_diagnosis = JSON.parse(this.formData.diagnosis);
                this.complicationList = JSON.parse(this.formData.complication);
                this.comobidityList = JSON.parse(this.formData.comorbidity);
                this.operationList = JSON.parse(this.formData.operation);
              }
              let refer = response.refer;
              this.isAdmin = this.user ? this.user.role == 1 : false;
              let isRefer = false;
              if (refer) {
                if (this.user.agency_code == refer.from && refer.status == 0)
                  isRefer = false;
                else if (
                  this.user.agency_code == refer.from &&
                  refer.status == 1
                )
                  isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 0)
                  isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 1)
                  isRefer = false;
              }
              if (!this.isAdmin) this.isAdmin = isRefer;
            });
        }
        Swal.clickConfirm();
      },
    });
  },
  methods: {
    onSelect(keyword) {
      if (keyword) {
        this.model = keyword;
        this.hspModel = keyword;
        this.formData.hosp_code = keyword.hospcode;
        this.formData.hosp_name = keyword.name;
      } else {
        this.model = null;
        this.hspModel = null;
        this.formData.hosp_code = "";
        this.formData.hosp_name = "";
      }
    },
    async searchHosp(val, key) {
      if (this.isLoading) return;
      this.isLoading = true;
      let model = {};
      model[key] = val;
      await this.$store
        .dispatch("MasterData/searchHospCode", model)
        .then((response) => {
          this.hospitalList = response.data;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    gotoPregnant() {
      this.$router.push({
        path: "pregnant",
        query: { id: null, form: this.mainForm.id },
      });
    },
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    getHospitalName() {
      if (this.formData.hosp_code.length == 5) {
        this.showLoading();
        let data = {
          code: this.formData.hosp_code,
        };
        this.$store.dispatch("Auth/hospcode", data).then((res) => {
          if (res.data) {
            this.formData.hosp_name = res.data.name;
            this.hideLoading();
          } else {
            this.$swal.fire({
              title: "ไม่พบรหัสนี้",
              confirmButtonText: "ยืนยัน",
            });
          }
        });
      } else {
        this.formData.hosp_name = "";
      }
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    setData(model) {
      this.formData[model.name] = model.value;
    },
    getDiagnosis() {
      if (this.priciple_diagnosis.code.length > 3) {
        return Service.MasterData.getDiagnosis(
          this.priciple_diagnosis.code
        ).then((response) => {
          this.priciple_diagnosis.descriptiopn = response.data.name;
        });
      }
    },
    getComobidity(index) {
      if (this.comobidityList[index].code.length > 3) {
        return Service.MasterData.getDiagnosis(
          this.comobidityList[index].code
        ).then((response) => {
          this.comobidityList[index].descriptiopn = response.data.name;
        });
      }
    },
    getComplication(index) {
      if (this.complicationList[index].code.length > 3) {
        return Service.MasterData.getDiagnosis(
          this.complicationList[index].code
        ).then((response) => {
          this.complicationList[index].descriptiopn = response.data.name;
        });
      }
    },
    getOperation(index) {
      if (this.operationList[index].code.length > 3) {
        return Service.MasterData.getDiagnosis(
          this.operationList[index].code
        ).then((response) => {
          this.operationList[index].descriptiopn = response.data.name;
        });
      }
    },
    save() {
      this.formData.forward_file = JSON.stringify(this.forwardFile);
      this.formData.diagnosis = JSON.stringify(this.priciple_diagnosis);
      this.formData.comorbidity = JSON.stringify(this.comobidityList);
      this.formData.complication = JSON.stringify(this.complicationList);
      this.formData.operation = JSON.stringify(this.operationList);
      this.formData.user_id = this.user ? this.user.id : "";
      if (this.formData.service_status_denied != "ส่งต่อไปรักษายังหน่วยงานอื่น ๆ") {
        this.formData.hosp_code = null;
        this.formData.hosp_name = null;
      }
      // if (!this.formData['estimate_age_result'])
      //     this.$swal.fire({
      //         title: "กรุณาเลือก การตรวจประเมินอายุ(6.1)",
      //         confirmButtonText: "ยืนยัน",
      //     });
      // else
      if (this.formData['estimate_age_result'] && !this.formData.lowest_age && !this.formData.most_age)
          this.$swal.fire({
              title: "กรุณาเลือก อายุน้อยสุด หรืออายุมากสุด",
              confirmButtonText: "ยืนยัน",
          });
      else if(this.formData['estimate_age_result'] && (this.formData.lowest_age || this.formData.most_age)){
          if (this.formData.lowest_age && this.formData.lowest_age_year == 0 && this.formData.lowest_age_month == 0)
              this.$swal.fire({
                  title: "กรุณาระบุปี หรือเดือน ของอายุน้อยสุด",
                  confirmButtonText: "ยืนยัน",
              });
          else if (this.formData.most_age && this.formData.most_age_year == 0 && this.formData.most_age_month == 0)
              this.$swal.fire({
                  title: "กรุณาระบุปี หรือเดือน ของอายุมากสุด",
                  confirmButtonText: "ยืนยัน",
              });
          else{
              Swal.fire({
                  title: "กำลังบันทึกข้อมูล",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                      Swal.showLoading();
                      this.$store
                          .dispatch("Violence/saveForm5", this.formData)
                          .then((response) => {
                              Swal.close();
                              if (response.success) {
                                  Swal.fire({
                                      icon: "success",
                                      title: "บันทึกสำเร็จ",
                                      confirmButtonText: "ตกลง",
                                      confirmButtonColor: "#4579DF",
                                  }).then(() => {
                                      this.$router.push({
                                          path: "result",
                                      });
                                  });
                              } else {
                                  Swal.fire({
                                      icon: "error",
                                      title: "บันทึกไม่สำเร็จ",
                                      confirmButtonText: "ตกลง",
                                      confirmButtonColor: "#4579DF",
                                  });
                              }
                          });
                  },
              });
          }
      }
      else{
          Swal.fire({
              title: "กำลังบันทึกข้อมูล",
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading();
                  this.$store
                      .dispatch("Violence/saveForm5", this.formData)
                      .then((response) => {
                          Swal.close();
                          if (response.success) {
                              Swal.fire({
                                  icon: "success",
                                  title: "บันทึกสำเร็จ",
                                  confirmButtonText: "ตกลง",
                                  confirmButtonColor: "#4579DF",
                              }).then(() => {
                                  this.$router.push({
                                      path: "result",
                                  });
                              });
                          } else {
                              Swal.fire({
                                  icon: "error",
                                  title: "บันทึกไม่สำเร็จ",
                                  confirmButtonText: "ตกลง",
                                  confirmButtonColor: "#4579DF",
                              });
                          }
                      });
              },
          });
      }
    },
    forwardFileRemove() {
      this.forwardFile = {
        path: "",
        name: "",
      };
    },
    forwardFileUpload(model) {
      this.forwardFile = {
        path: model.file,
        name: model.name,
      };
    },
    addComobidity() {
      this.comobidityList.push({ code: "", descriptiopn: "" });
    },
    removeComobidity(index) {
      if (this.comobidityList.length === 1) {
        this.comobidityList[0].code = "";
        this.comobidityList[0].descriptiopn = "";
      } else {
        this.comobidityList.splice(index, 1);
      }
    },
    addComplication() {
      this.complicationList.push({ code: "", descriptiopn: "" });
    },
    removeComplication(index) {
      if (this.complicationList.length === 1) {
        this.complicationList[0].code = "";
        this.complicationList[0].descriptiopn = "";
      } else {
        this.complicationList.splice(index, 1);
      }
    },
    addOperation() {
      this.operationList.push({ code: "", descriptiopn: "" });
    },
    removeOperation(index) {
      if (this.operationList.length === 1) {
        this.operationList[0].code = "";
        this.operationList[0].descriptiopn = "";
      } else {
        this.operationList.splice(index, 1);
      }
    },
  },
  data() {
    return {
      search: null,
      model: null,
      hspSearch: null,
      hspModel: null,
      isAdmin: false,
      isLoading: false,
      hospitalList: [],
      mainForm: {
        oscc_code: 0,
      },
      forwardFile: {
        path: "",
        name: "",
      },
      priciple_diagnosis: {
        code: "",
        descriptiopn: "",
      },
      formData: {
        form_id: "",
        hosp_code: "",
        hosp_name: "",
        forward_file: [],
        medical_diagnosis: false,
        law_operation: false,
        pathology_diagnosis: false,
        cure_procedure: false,
        cure_injury: false,
        medicine_birth_control: false,
        medicine_hiv: false,
        medicine_sex_infection: false,
        antenatal_care: false,
        abortion: false,
        birth_control: false,
        medical_recovery: false,
        follow_up_treatment: false,
        send_to_specialist: false,
        autopsy_opinion: false,
        estimate_age_result: false,
        lowest_age: false,
        most_age: false,
        lowest_age_year: 0,
        lowest_age_month: 0,
        most_age_year: 0,
        most_age_month: 0,
        assessment_diagnosis: false,
        ptsd_test: false,
        psycho_test: false,
        iq_test: false,
        consulting_mental_health: false,
        treatment: false,
        counseling: false,
        psycho_therapy: false,
        family_therapy: false,
        cbt_therapy: false,
        medicine_therapy: false,
        forensic_psychiatry: false,
        diagnosis_social: false,
        therapy_social: false,
        prevent_social_problem: false,
        social_recovery_performance: false,
        resource_social_management: false,
        follow_up_after_Illness: false,
        legal_cooperate: false,
        principle_diagnosis: false,
        is_comorbidity: false,
        is_complication: false,
        is_operation: false,
        diagnosis: [],
        comorbidity: [],
        complication: [],
        operation: [],
        police_report: false,
        police_report_place: false,
        service_status: true,
        service_status_complete: false,
        service_status_denied: false,
        service_status_death: false,
        service_status_not_track: false,
        service_status_forward: false,
        law_operation_place: "",
        service_status_forward_hospital: false,
      },
      isBool: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      therapy: [
        { name: "การประเมินและตรวจวินิจฉัย", key: "assessment_diagnosis" },
        {
          name: "การให้คำปรึกษาและสุขภาพจิตศึกษา",
          key: "consulting_mental_health",
        },
        { name: "บำบัดรักษา", key: "treatment" },
        { name: "การบริการนิติจิตเวช", key: "forensic_psychiatry" },
      ],
      service: [
        { name: "อยู่ระหว่างดำเนินการ", value: true, grid: "col-12" },
        { name: "สิ้นสุดการให้บริการ", value: false, grid: "col-12" },
      ],
      subService: [
        { name: "ให้บริการครบตามที่วางแผน", grid: "col-12" },
        { name: "ปฏิเสธการรับบริการ", grid: "col-12" },
        { name: "เสียชีวิต", grid: "col-12" },
        { name: "ติดตามไม่ได้", grid: "col-12" },
        { name: "ส่งต่อไปรักษายังหน่วยงานอื่น ๆ", grid: "col-12" },
      ],
      violenceFileList: [""],
      labFileList: [""],
      obstetricsFileList: [""],
      comobidityList: [{ code: "", descriptiopn: "" }],
      complicationList: [{ code: "", descriptiopn: "" }],
      operationList: [{ code: "", descriptiopn: "" }],
      social: [
        { name: "ประเมินและวินิจฉัยทางสังคม", key: "diagnosis_social" },
        { name: "การบำบัดทางสังคม", key: "therapy_social" },
        { name: "ป้องกันปัญหาทางสังคม", key: "prevent_social_problem" },
        {
          name: "การฟื้นฟูสมรรถภาพทางสังคม",
          key: "social_recovery_performance",
        },
        {
          name: "การจัดการทรัพยากรทางสังคมและพิทักษ์สิทธิ์",
          key: "resource_social_management",
        },
        {
          name: "ติดตามผลประเมินคุณภาพชีวิต และติดตามป้องกันการเจ็บป่วยซ้ำผู้ป่วยหลังจำหน่าย",
          key: "follow_up_after_Illness",
        },
        { name: "ประสานทางกฎหมาย", key: "legal_cooperate" },
      ],
    };
  },
};
</script>

<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>

