<template>
  <div class="row align-items-center">
    <div class="col-8 align-items-center text-right">
      <small >แนบไฟล์</small>
      <label class=" form-control pointer" :for="`upload-${name}-${index}`">    
        {{filename}}
        <i v-if="!filename" class="bi bi-file-earmark-text"></i>
      </label>
      <input
        @change="uploadFile($event, index)"
        :id="`upload-${name}-${index}`"
        style="visibility: hidden; width: 0px"
        type="file"
        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
      />
      <!-- <input class="form-control checkbox-border mt-2" type="text" /> -->
    </div>
    <div class="col-2">
      <button
        @click="removeFile(index)"
        class="btn btn-outline-dark btn-circle"
      >
        <i class="bi bi-trash"></i>
      </button>
    </div>
    <div class="col-2">
      <!-- <button @click="addFile" class="btn btn-outline-dark btn-circle">
        <i class="bi bi-plus-lg"></i>
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["model", "index","name","setData"],
  created(){
     this.filename = this.setData.name
  },
  watch: {
    setData: function (newVal) {
        this.filename = newVal.name
    },
  },
  methods: {
    addFile() {
      this.$emit("addFile");
    },
    uploadFile(event, index) {
      let input = event.target;
      let file = input.files[0];
      const filename = file.name
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("folder", this.name);
        this.$store.dispatch("Auth/getPathDocument", formData).then((path_file) => {
          this.filename = filename
          this.sentPath(path_file.data, index);
        });
      }
    },
    sentPath(path_file,index){
      this.$emit("uploadFile", {file:path_file,name:this.filename, index:index});
    },
    removeFile(index) {
      this.$emit("removeFile", index);
    },
  },
  data() {
    return {
      fileList: [1],
      filename:null
    };
  },
};
</script>

<style>
</style>