<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>3. ประเภทของการกระทำรุนแรง</div>
          <div>หมายเลขเอกสาร : {{ mainForm.oscc_code }}</div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3 required">
          3.1 ข้อมูลการกระทำความรุนแรง
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <RadioGroup
                pattern="violence"
                :setData="formData.violence_situation"
                @clickAnswer="formData.violence_situation = $event"
                :choice="isDoerFirstTime"
              ></RadioGroup>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="required">วันที่เกิดเหตุการณ์</label>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    rounded
                    class="mt-2"
                    dense
                    v-model="computedEventDateFormatted"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.event_date"
                  @input="menu1 = false"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
              <small v-if="!formData.event_date" class="text-danger"
                >กรุณาระบุวันที่เกิดเหตุการณ์</small
              >
            </div>
            <div class="col-2">
              <label class="required">เวลา</label>
              <input
                v-model="formData.event_time"
                @click="modal2 = !isAdmin"
                class="form-control mt-2"
                type="text"
                readonly
              />
              <small v-if="!formData.event_time" class="text-danger"
                >กรุณาระบุเวลาที่เกิดเหตุการณ์</small
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="required">วันที่มาโรงพยาบาล</label>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    rounded
                    class="mt-2"
                    dense
                    v-model="computedHospitalDateFormatted"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.hospital_date"
                  @input="menu2 = false"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
              <small v-if="!formData.hospital_date" class="text-danger"
                >กรุณาระบุวัน/เดือน/ปีที่ มาโรงพยาบาล</small
              >
            </div>
            <div class="col-2">
              <label class="required">เวลา</label>
              <input
                v-model="formData.hospital_time"
                @click="modal3 = !isAdmin"
                class="form-control mt-2"
                type="text"
                readonly
              />
              <small v-if="!formData.hospital_time" class="text-danger"
                >กรุณาระบุเวลาที่มาโรงพยาบาล</small
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label-for="event_place">
                <template #label>
                  สถานที่เกิดเหตุการณ์<span class="star-red">*</span>
                </template>
                <b-form-select
                  :disabled="isAdmin"
                  id="event_place"
                  :state="validateState('event_place')"
                  class="form-control mt-2"
                  v-model="$v.formData.event_place.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกสถานที่เกิดเหตุการณ์</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in place"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกสถานที่เกิดเหตุการณ์</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="required">วันที่เกิดเหตุการณ์ (ครั้งสุดท้าย)</label>
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    rounded
                    class="mt-2"
                    dense
                    v-model="computedRecentEventDateFormatted"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.recent_event_date"
                  @input="menu3 = false"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
              <small v-if="!formData.recent_event_date" class="text-danger"
                >กรุณาระบุวันที่เกิดเหตุการณ์ (ครั้งสุดท้าย)</small
              >
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">3.2 ผู้กระทำรุนแรง</div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <RadioGroup
                pattern="violence"
                :setData="formData.know_doer"
                @clickAnswer="knowDoerTrigger"
                :choice="knowDoerList"
              ></RadioGroup>
            </div>
            <div class="col-6"></div>
            <div class="col-4">
              <b-form-group id="victim_first_name">
                <template #label>
                  ชื่อ<span class="star-red">*</span>
                </template>
                <b-form-input
                  :readonly="isAdmin || !formData.know_doer"
                  id="victim_first_name"
                  name="victim_first_name"
                  v-model="$v.formData.victim_first_name.$model"
                  :state="validateState('victim_first_name')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณาระบุชื่อ.</b-form-invalid-feedback
                >
              </b-form-group>

              <!-- <label>ชื่อ</label>
              <input
                v-model="formData.victim_first_name"
                class="form-control mt-2"
                type="text"
              /> -->
            </div>
            <div class="col-4">
              <b-form-group id="victim_last_name">
                <template #label>
                  นามสกุล<span class="star-red">*</span>
                </template>
                <b-form-input
                  :readonly="isAdmin || !formData.know_doer"
                  id="victim_last_name"
                  name="victim_last_name"
                  v-model="$v.formData.victim_last_name.$model"
                  :state="validateState('victim_last_name')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณาระบุนามสกุล</b-form-invalid-feedback
                >
              </b-form-group>

              <!-- <input
                v-model="formData.victim_last_name"
                class="form-control mt-2"
                type="text"
              /> -->
            </div>
            <div class="col-4">
              <b-form-group label-for="doer_relation">
                <template #label>
                  ความเกี่ยวข้อง<span class="star-red">*</span>
                </template>
                <b-form-select
                  :disabled="isAdmin"
                  id="doer_relation"
                  :state="validateState('doer_relation')"
                  class="form-control"
                  v-model="$v.formData.doer_relation.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกความเกี่ยวข้อง</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in familyRelation"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกความเกี่ยวข้อง</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          3.3 ปัจจัยกระตุ้นให้เกิดการกระทำรุนแรง (เลือกได้มากกว่า 1
          ตัวเลือก)<span class="star-red">*</span>
        </div>
        <div class="card-body row">
          <div class="col-6 mt-3">
            <div class="card">
              <div
                class="
                  card-header
                  bg-purple-3-theme
                  d-flex
                  justify-content-between
                "
              >
                <div>ผู้กระทำ<span class="star-red">*</span></div>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[doerStimulus.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="doerStimulus"
                  ></Checkbox>
                  <div v-if="formData.doer_cause" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iDoerCause) in doerCauseList"
                      :key="iDoerCause"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[doerFamily.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="doerFamily"
                  ></Checkbox>
                  <div v-if="formData.doer_family_status" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iDoerFamily) in doerFamilyList"
                      :key="iDoerFamily"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[doerEconomic.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="doerEconomic"
                  ></Checkbox>
                  <div v-if="formData.doer_problem_economic" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iDoerEconomic) in doerEconomicList"
                      :key="iDoerEconomic"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[doerHealth.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="doerHealth"
                  ></Checkbox>
                  <div v-if="formData.doer_problem_health" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iDoerHealth) in doerHealthList"
                      :key="iDoerHealth"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[doerMentalHealth.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="doerMentalHealth"
                  ></Checkbox>
                  <div v-if="formData.doer_problem_mind" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iDoerMental) in doerMentalList"
                      :key="iDoerMental"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[doerOther.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="doerOther"
                  ></Checkbox>
                  <div class="ml-5">
                    <input
                      v-if="formData.is_doer_problem_other"
                      v-model="formData.doer_problem_other"
                      :readonly="isAdmin"
                      type="text"
                      class="form-control mt-2"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6 mt-3">
            <div class="card">
              <div
                class="
                  card-header
                  bg-purple-3-theme
                  d-flex
                  justify-content-between
                "
              >
                <div>ผู้ถูกกระทำ<span class="star-red">*</span></div>
              </div>

              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[victimStimulus.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="victimStimulus"
                  ></Checkbox>
                  <div v-if="formData.victim_cause" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iVictimCause) in victimCauseList"
                      :key="iVictimCause"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[victimFamily.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="victimFamily"
                  ></Checkbox>
                  <div v-if="formData.victim_family_status" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iVictimFamily) in victimFamilyList"
                      :key="iVictimFamily"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[victimEconomic.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="victimEconomic"
                  ></Checkbox>
                  <div v-if="formData.victim_problem_economic" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iVictimEconomic) in victimEconomicList"
                      :key="iVictimEconomic"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[victimHealth.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="victimHealth"
                  ></Checkbox>
                  <div v-if="formData.victim_problem_health" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemVictim, iVictimHealth) in victimHealthList"
                      :key="iVictimHealth"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemVictim.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemVictim"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[victimMentalHealth.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="victimMentalHealth"
                  ></Checkbox>
                  <div v-if="formData.victim_problem_mind" class="ml-5">
                    <div
                      class="my-2"
                      v-for="(itemDoer, iVictimMental) in victimMentalList"
                      :key="iVictimMental"
                    >
                      <Checkbox
                        pattern="violence-checkbox"
                        :setData="formData[itemDoer.key]"
                        @clickAnswer="setData($event)"
                        :isSingle="false"
                        :model="itemDoer"
                      ></Checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[victimOther.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="false"
                    :model="victimOther"
                  ></Checkbox>
                  <div class="ml-5">
                    <input
                      v-if="formData.is_victim_problem_other"
                      v-model="formData.victim_problem_other"
                      :readonly="isAdmin"
                      type="text"
                      class="form-control mt-2"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          3.4 ลักษณะการกระทำความรุนแรง
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 mt-4">
              <label class="mb-2">3.4.1 การกระทำความรุนแรงทางร่างกาย</label>
              <RadioGroup
                pattern="violence"
                :setData="formData.victim_body_hurt"
                @clickAnswer="formData.victim_body_hurt = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.victim_body_hurt" class="col-6 mt-4">
              <label class="text-stroke">เลือกได้มากกว่า 1 ตัวเลือก</label>
              <div
                v-for="(item, iViolenceType) in violenceType"
                :key="iViolenceType"
              >
                <div class="checkbox-border px-2 py-2 my-3">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <label class="text-stroke mb-2"
                >3.4.2 การกระทำความรุนแรงทางเพศ</label
              >
              <RadioGroup
                pattern="violence"
                :setData="formData.victim_rape"
                @clickAnswer="formData.victim_rape = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
          </div>
          <div v-if="formData.victim_rape" class="row align-items-center">
            <div class="col-4 mt-4">
              <RadioGroup
                pattern="violence"
                :setData="formData.victim_rape_intention"
                @clickAnswer="formData.victim_rape_intention = $event"
                :choice="isConsent"
              ></RadioGroup>
            </div>
            <div class="col-8"></div>
            <div v-if="!formData.victim_rape_intention" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[notConsent[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="notConsent[0]"
                ></Checkbox>
              </div>
            </div>
            <div class="col-6"></div>
            <div
              v-if="
                formData.victim_rape_action && !formData.victim_rape_intention
              "
              class="offset-4 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[victimRapeAction[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="victimRapeAction[0]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                formData.victim_rape_action && !formData.victim_rape_intention
              "
              class="col-4"
            ></div>
            <div
              v-if="
                formData.victim_rape_action && !formData.victim_rape_intention
              "
              class="offset-4 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[victimRapeAction[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="victimRapeAction[1]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                formData.victim_rape_action && !formData.victim_rape_intention
              "
              class="col-4"
            ></div>
            <div
              v-if="
                formData.victim_rape_action &&
                formData.victim_rape_shy &&
                !formData.victim_rape_intention
              "
              class="offset-6 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[indecent[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="indecent[0]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                formData.victim_rape_action &&
                formData.victim_rape_shy &&
                !formData.victim_rape_intention
              "
              class="offset-6 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[indecent[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="indecent[1]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                formData.victim_rape_action &&
                formData.victim_rape_shy &&
                !formData.victim_rape_intention
              "
              class="offset-6 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[indecent[2].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="indecent[2]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                formData.victim_rape_action &&
                formData.victim_rape_shy &&
                !formData.victim_rape_intention
              "
              class="offset-6 col-2"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[indicentOther.key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="indicentOther"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                formData.victim_rape_action &&
                formData.victim_rape_shy &&
                formData.victim_rape_other &&
                !formData.victim_rape_intention
              "
              class="col-2"
            >
              <input
                :readonly="isAdmin"
                v-model="formData.victim_rape_other_description"
                type="text"
                class="form-control"
              />
            </div>
            <div v-if="!formData.victim_rape_intention" class="offset-2 col-4">
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[notConsent[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="notConsent[1]"
                ></Checkbox>
              </div>
            </div>
            <div class="col-6"></div>
            <div
              v-if="
                !formData.victim_rape_intention && formData.victim_rape_coerce
              "
              class="offset-4 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[coerce[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="coerce[0]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                !formData.victim_rape_intention &&
                formData.victim_rape_coerce_desc &&
                formData.victim_rape_coerce
              "
              class="col-1"
            >
              ระบุ
            </div>
            <div
              v-if="
                !formData.victim_rape_intention &&
                formData.victim_rape_coerce_desc &&
                formData.victim_rape_coerce
              "
              class="col-3"
            >
              <input
                :readonly="isAdmin"
                v-model="formData.victim_rape_coerce_description"
                type="text"
                class="form-control"
              />
            </div>
            <div
              v-if="
                !formData.victim_rape_intention && formData.victim_rape_coerce
              "
              class="offset-4 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[coerce[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="coerce[1]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                !formData.victim_rape_intention && formData.victim_rape_coerce
              "
              class="col-4"
            ></div>
            <div
              v-if="
                !formData.victim_rape_intention &&
                formData.victim_rape_force &&
                formData.victim_rape_coerce
              "
              class="offset-6 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[victimRapeForce[0].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="victimRapeForce[0]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                !formData.victim_rape_intention &&
                formData.victim_rape_force &&
                formData.victim_rape_coerce
              "
              class="offset-6 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[victimRapeForce[1].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="victimRapeForce[1]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                !formData.victim_rape_intention &&
                formData.victim_rape_force &&
                formData.victim_rape_coerce
              "
              class="offset-6 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[victimRapeForce[2].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="victimRapeForce[2]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                !formData.victim_rape_intention && formData.victim_rape_coerce
              "
              class="offset-4 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[coerce[2].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="coerce[2]"
                ></Checkbox>
              </div>
            </div>
            <div
              v-if="
                !formData.victim_rape_intention && formData.victim_rape_coerce
              "
              class="col-4"
            ></div>
            <div
              v-if="
                !formData.victim_rape_intention && formData.victim_rape_coerce
              "
              class="offset-4 col-4"
            >
              <div class="checkbox-border px-2 py-2 my-2">
                <Checkbox
                  pattern="violence-checkbox"
                  :setData="formData[coerce[3].key]"
                  @clickAnswer="setData($event)"
                  :isSingle="true"
                  :model="coerce[3]"
                ></Checkbox>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <label class="text-stroke mb-2"
                >3.4.3 การกระทำความรุนแรงทางจิตใจ</label
              >
              <RadioGroup
                pattern="violence"
                :setData="formData.victim_mind_effect"
                @clickAnswer="formData.victim_mind_effect = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.victim_mind_effect" class="col-6">
              <div v-for="(item, iMental) in mentalViolence" :key="iMental">
                <div class="checkbox-border px-2 py-2 my-3">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <label class="text-stroke mb-2 my-2">3.4.4 การละเลยทอดทิ้ง</label>
              <RadioGroup
                pattern="violence"
                :setData="formData.victim_neglect"
                @clickAnswer="formData.victim_neglect = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.victim_neglect" class="col-6">
              <div v-for="(item, iAbandon) in abandon" :key="iAbandon">
                <div class="checkbox-border px-2 py-2 my-2">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <label class="text-stroke mb-2"
                >3.4.5 การล่อลวง/บังคับแสวงหาประโยชน์
              </label>
              <RadioGroup
                pattern="violence"
                :setData="formData.victim_cozen"
                @clickAnswer="formData.victim_cozen = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
            <div v-if="formData.victim_cozen" class="col-6">
              <div v-for="(item, iTemptation) in temptation" :key="iTemptation">
                <div class="checkbox-border px-2 py-2 my-3">
                  <Checkbox
                    pattern="violence-checkbox"
                    :setData="formData[item.key]"
                    @clickAnswer="setData($event)"
                    :isSingle="true"
                    :model="item"
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button
          :disabled="isAdmin"
          @click="save(false)"
          class="btn btn-save-outline"
        >
          บันทึก
        </button>
      </div>
      <div class="col-3">
        <button
          :disabled="isAdmin"
          @click="save(true)"
          class="btn btn-next-outline"
        >
          ถัดไป
        </button>
      </div>
    </div>
    <v-dialog
      ref="dialog"
      v-model="modal2"
      :return-value.sync="formData.event_time"
      persistent
      width="290px"
    >
      <v-time-picker
        format="24hr"
        v-if="modal2"
        v-model="formData.event_time"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal2 = false"> Cancel </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog.save(formData.event_time)"
        >
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>

    <v-dialog
      ref="dialog3"
      v-model="modal3"
      :return-value.sync="formData.hospital_time"
      persistent
      width="290px"
    >
      <v-time-picker
        format="24hr"
        v-if="modal3"
        v-model="formData.hospital_time"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal3 = false"> Cancel </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog3.save(formData.hospital_time)"
        >
          OK
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>
<script>
import RadioGroup from "../RadioGroup.vue";
import Checkbox from "../Checkbox.vue";
import Swal from "sweetalert2";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],
  computed: {
    computedEventDateFormatted() {
      if (!this.formData.event_date) return null;
      const [year, month, day] = this.formData.event_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedHospitalDateFormatted() {
      if (!this.formData.hospital_date) return null;
      const [year, month, day] = this.formData.hospital_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedRecentEventDateFormatted() {
      if (!this.formData.recent_event_date) return null;
      const [year, month, day] = this.formData.recent_event_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    ...mapState({
      place: (state) => {
        return state.MasterData.form2.place;
      },
      familyRelation: (state) => {
        return state.MasterData.form2.familyRelation;
      },
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  components: {
    RadioGroup,
    Checkbox,
    //DatePicker,
  },
  async created() {
    await this.$store.dispatch("MasterData/setForm2");

    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        if (this.$route.query.form) {
          this.formData.form_id = this.$route.query.form;
          await this.$store
            .dispatch("Violence/showForm2", this.$route.query.form)
            .then((response) => {
              if (response.main_form) {
                this.mainForm = response.main_form;
              }
              let refer = response.refer;
              this.isAdmin = this.user ? this.user.role == 1 : false;
              let isRefer = false;
              if (refer) {
                if (this.user.agency_code == refer.from && refer.status == 0)
                  isRefer = false;
                else if (
                  this.user.agency_code == refer.from &&
                  refer.status == 1
                )
                  isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 0)
                  isRefer = true;
                else if (this.user.agency_code == refer.to && refer.status == 1)
                  isRefer = false;
              }
              if (!this.isAdmin) this.isAdmin = isRefer;
              if (response.form) {
                this.formData = response.form;
                this.formData.event_time = moment(
                  response.form.event_time,
                  "HH:mm:ss"
                ).format("HH:mm");
                this.formData.hospital_time = moment(
                  response.form.hospital_time,
                  "HH:mm:ss"
                ).format("HH:mm");

                this.formData.event_date = moment(
                  response.form.event_date,
                  "YYYY-MM-DD"
                )
                  .subtract(543, "years")
                  .format("YYYY-MM-DD");
                this.formData.hospital_date = moment(
                  response.form.hospital_date,
                  "YYYY-MM-DD"
                )
                  .subtract(543, "years")
                  .format("YYYY-MM-DD");
                // this.formData.hospital_date = moment(
                //   response.form.hospital_date,
                //   "YYYY-MM-DD"
                // ).format("DD-MM-YYYY");
                this.formData.recent_event_date = moment(
                  response.form.recent_event_date,
                  "YYYY-MM-DD"
                )
                  .subtract(543, "years")
                  .format("YYYY-MM-DD");
                // this.formData.recent_event_date = moment(
                //   response.form.recent_event_date,
                //   "YYYY-MM-DD"
                // ).format("DD-MM-YYYY");
              } else {
                //this.convertDate("event_date");
                //this.convertDate("hospital_date");
                //this.convertDate("recent_event_date");
              }
            });
        } else {
          //this.convertDate("event_date");
          //this.convertDate("hospital_date");
          //this.convertDate("recent_event_date");
        }
        Swal.clickConfirm();
      },
    });
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      mainForm: {
        oscc_code: 0,
      },
      time: null,
      modal2: false,
      modal3: false,
      isViolenceCause: false,
      isDoerCause: false,
      isViolenceFamily: false,
      isDoerFamily: false,
      isViolenceEconomic: false,
      isDoerEconomic: false,
      isViolenceHealth: false,
      isDoerHealth: false,
      isAdmin: false,
      mentalViolence: [
        { name: "กักขังหน่วงเหนี่ยว", key: "victim_cage" },
        { name: "ดูถูกเหยียดหยาม", key: "victim_hate_speech" },
        { name: "ข่มขู่", key: "victim_intimidate" },
        { name: "ทำให้หวาดกลัว", key: "victim_fear" },
        { name: "เฉยเมย ไม่สนใจ", key: "victim_ignore" },
        { name: "ทะเลาะ", key: "victim_quarrel" },
        { name: "ทำร้ายต่อหน้าเด็ก", key: "victim_hurt_on_child" },
      ],
      abandon: [
        { name: "ละเลย", key: "victim_neglect_action" },
        { name: "ทอดทิ้ง", key: "victim_left" },
      ],
      temptation: [
        { name: "แสวงหาผลประโยชน์โดยมิชอบ", key: "victim_cozen_force" },
        { name: "การบังคับใช้หรือบริการ", key: "victim_cozen_service" },
        { name: "การค้าประเวณี", key: "victim_cozen_sell" },
        { name: "แรงงานเด็กในรูปแบบที่เลวร้าย", key: "victim_cozen_child" },
        { name: "ค้ามนุษย์", key: "victim_cozen_trafficking" },
      ],
      indecent: [
        { name: "ให้แสดงหรือดูรูปภาพ", key: "victim_rape_content" },
        { name: "การเปิดของสงวน", key: "victim_rape_nude" },
        { name: "กอดจูบลูบคลำ", key: "victim_rape_touch" },
      ],
      indicentOther: {
        name: "อื่นๆ",
        is_other: true,
        key: "victim_rape_other",
      },
      coerce: [
        { name: "การขู่เข็ญด้วยประการใด", key: "victim_rape_coerce_desc" },
        { name: "การใช้กำลังประทุษร้ายด้วยการ", key: "victim_rape_force" },
        {
          name: "การทำให้อยู่ในภาวะที่ไม่สามารถขัดขืนได้",
          key: "victim_rape_restrain",
        },
        {
          name: "การทำให้เข้าใจผิดว่าผู้กระทำเป็นบุคคลอื่น ๆ",
          key: "victim_rape_fake",
        },
      ],
      victimRapeAction: [
        { name: "กระทำชำเรา", key: "victim_rape_hurt" },
        { name: "กระทำอนาจาร", key: "victim_rape_shy" },
      ],
      victimRapeForce: [
        { name: "ทำร้ายร่างกาย", key: "victim_rape_force_body" },
        { name: "ใช้สาร", key: "victim_rape_force_drug" },
        { name: "เหล้า", key: "victim_rape_force_alcohol" },
      ],
      isDoerFirstTime: [
        { name: "ถูกกระทำรุนแรงครั้งแรก", grid: "col-5" },
        { name: "เคยถูกกระทำรุนแรงมาก่อน", grid: "col-5" },
      ],
      notConsent: [
        { name: "พฤติกรรมการกระทำ", key: "victim_rape_action" },
        { name: "ลักษณะการขู่เข็ญ", key: "victim_rape_coerce" },
      ],
      victimStimulus: {
        name: "สิ่งกระตุ้น",
        key: "victim_cause",
      },
      doerStimulus: {
        name: "สิ่งกระตุ้น",
        key: "doer_cause",
      },
      victimFamily: {
        name: "สัมพันธภาพในครอบครัว",
        key: "victim_family_status",
      },
      doerFamily: {
        name: "สัมพันธภาพในครอบครัว",
        key: "doer_family_status",
      },
      victimEconomic: {
        name: "ปัญหาเศรษฐกิจ",
        key: "victim_problem_economic",
      },
      doerEconomic: {
        name: "ปัญหาเศรษฐกิจ",
        key: "doer_problem_economic",
      },
      victimHealth: {
        name: "ปัญหาสุขภาพกาย",
        key: "victim_problem_health",
      },
      doerHealth: {
        name: "ปัญหาสุขภาพกาย",
        key: "doer_problem_health",
      },
      victimMentalHealth: {
        name: "ปัญหาสุขภาพจิต",
        key: "victim_problem_mind",
      },
      doerMentalHealth: {
        name: "ปัญหาสุขภาพจิต",
        key: "doer_problem_mind",
      },
      victimOther: {
        name: "อื่น ๆ ระบุ",
        key: "is_victim_problem_other",
      },
      doerOther: {
        name: "อื่น ๆ ระบุ",
        key: "is_doer_problem_other",
      },
      isBool: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      isConsent: [
        { name: "โดยสมัครใจ", value: true, grid: "col-12" },
        { name: "โดยไม่สมัครใจ", value: false, grid: "col-12" },
      ],
      knowDoerList: [
        { name: "ทราบผู้กระทำ", value: true, grid: "col-6" },
        { name: "ไม่ทราบผู้กระทำ", value: false, grid: "col-6" },
      ],
      violenceType: [
        { name: "ใช้กำลัง", key: "power_hurt" },
        { name: "ใช้อาวุธ", key: "weapon_hurt" },
        { name: "มอมยา", key: "drug_hurt" },
        { name: "มอมเหล้า", key: "alcohal_hurt" },
      ],
      doerCauseList: [
        { name: "สุรา", key: "doer_cause_alcohol" },
        { name: "สื่อลามก", key: "doer_cause_porn" },
        { name: "ยาบ้า", key: "doer_cause_amphetamine" },
        { name: "สารเสพติด", key: "doer_cause_drug" },
        { name: "สื่อออนไลน์", key: "doer_cause_online" },
        { name: "รายได้", key: "doer_cause_income" },
      ],
      victimCauseList: [
        { name: "สุรา", key: "victim_cause_alcohol" },
        { name: "สื่อลามก", key: "victim_cause_porn" },
        { name: "ยาบ้า", key: "victim_cause_amphetamine" },
        { name: "สารเสพติด", key: "victim_cause_drug" },
        { name: "สื่อออนไลน์", key: "victim_cause_online" },
        { name: "รายได้", key: "victim_cause_income" },
      ],
      doerFamilyList: [
        { name: "นอกใจ/หึงหวง", key: "doer_family_cheat" },
        { name: "ทะเลาะวิวาท", key: "doer_family_fight" },
        { name: "การใช้ความรุนแรงในครอบครัว", key: "doer_family_violence" },
      ],
      victimFamilyList: [
        { name: "นอกใจ/หึงหวง", key: "victim_family_cheat" },
        { name: "ทะเลาะวิวาท", key: "victim_family_fight" },
        { name: "การใช้ความรุนแรงในครอบครัว", key: "victim_family_violence" },
      ],
      doerEconomicList: [
        { name: "ว่างงาน", key: "doer_economic_unemployed" },
        { name: "หนี้สิน", key: "doer_economic_debt" },
        { name: "ยากจน", key: "doer_economic_poor" },
        { name: "การพนัน", key: "doer_economic_gambling" },
      ],
      victimEconomicList: [
        { name: "ว่างงาน", key: "victim_economic_unemployed" },
        { name: "หนี้สิน", key: "victim_economic_debt" },
        { name: "ยากจน", key: "victim_economic_poor" },
        { name: "การพนัน", key: "victim_economic_gambling" },
      ],
      doerHealthList: [
        { name: "เจ็บป่วยเรื้อรัง", key: "doer_health_sick" },
        { name: "พิการ", key: "doer_health_disabled" },
      ],
      victimHealthList: [
        { name: "เจ็บป่วยเรื้อรัง", key: "victim_health_sick" },
        { name: "พิการ", key: "victim_health_disabled" },
      ],
      doerMentalList: [
        { name: "ป่วยเป็นโรคจิตเวช", key: "doer_mental_illness" },
        { name: "ปัญหาทางพฤติกรรม", key: "doer_mental_behavior" },
        { name: "ปัญหาด้านอารมณ์/จิตใจ", key: "doer_mental_mood_mind" },
      ],
      victimMentalList: [
        { name: "ป่วยเป็นโรคจิตเวช", key: "victim_mental_illness" },
        { name: "ปัญหาทางพฤติกรรม", key: "victim_mental_behavior" },
        { name: "ปัญหาด้านอารมณ์/จิตใจ", key: "victim_mental_mood_mind" },
      ],
      family_status: [],
      problem_economic: [],
      problem_health: [],
      problem_mind: [],
      formData: {
        form_id: "",
        user_id: "",
        violence_situation: "ถูกกระทำรุนแรงครั้งแรก",
        victim_rape_coerce_description: "",
        event_date: "",
        event_time: moment().format("HH:mm"),
        hospital_date: "",
        hospital_time: moment().format("HH:mm"),
        event_place: "",
        recent_event_date: "",
        victim_first_name: "",
        victim_last_name: "",
        victim_rape_other_description: "",
        doer_relation: "",
        doer_cause: "",
        victim_cause: "",
        victim_family_status: "",
        doer_family_status: "",
        doer_problem_economic: "",
        victim_problem_economic: "",
        doer_problem_health: "",
        victim_problem_health: "",
        doer_problem_mind: "",
        victim_problem_mind: "",
        doer_problem_other: "",
        is_doer_problem_other: false,
        is_victim_problem_other: false,
        victim_problem_other: "",
        victim_body_hurt: false,
        power_hurt: false,
        weapon_hurt: false,
        drug_hurt: false,
        alcohol_hurt: false,
        victim_rape: false,
        victim_rape_intention: false,
        victim_rape_action: false,
        victim_rape_hurt: false,
        victim_rape_shy: false,
        victim_rape_content: false,
        victim_rape_nude: false,
        victim_rape_touch: false,
        victim_rape_other: "",
        victim_rape_coerce: false,
        victim_rape_coerce_desc: "",
        victim_rape_force: false,
        victim_rape_force_alcohol: false,
        victim_rape_restrain: false,
        victim_health_sick: false,
        victim_rape_fake: false,
        victim_mind_effect: false,
        victim_cage: false,
        victim_hate_speech: false,
        victim_intimidate: false,
        victim_fear: false,
        victim_ignore: false,
        victim_hurt_on_child: false,
        victim_neglect: false,
        victim_neglect_action: false,
        victim_left: false,
        victim_cozen: false,
        victim_cozen_force: false,
        victim_cozen_service: false,
        victim_cozen_sell: false,
        victim_cozen_child: false,
        know_doer: true,
      },
      validateThreeOne: [
        "doer_cause_alcohol",
        "doer_cause_porn",
        "doer_cause_amphetamine",
        "doer_cause_drug",
        "doer_cause_online",
        "doer_cause_income",
        "doer_family_cheat",
        "doer_family_fight",
        "doer_family_violence",
        "doer_economic_unemployed",
        "doer_economic_debt",
        "doer_economic_poor",
        "doer_economic_gambling",
        "doer_health_sick",
        "doer_health_disabled",
        "doer_mental_illness",
        "doer_mental_behavior",
        "doer_mental_mood_mind",
      ],
      validateThreeTwo: [
        "victim_cause_alcohol",
        "victim_cause_porn",
        "victim_cause_amphetamine",
        "victim_cause_drug",
        "victim_cause_online",
        "victim_cause_income",
        "victim_family_cheat",
        "victim_family_fight",
        "victim_family_violence",
        "victim_economic_unemployed",
        "victim_economic_debt",
        "victim_economic_poor",
        "victim_economic_gambling",
        "victim_health_sick",
        "victim_health_disabled",
        "victim_mental_illness",
        "victim_mental_behavior",
        "victim_mental_mood_mind",
      ],
      headThreeOne: [
        "doer_cause",
        "doer_family_status",
        "doer_problem_economic",
        "doer_problem_health",
        "doer_problem_mind",
        "is_doer_problem_other",
      ],
      headThreeTwo: [
        "victim_cause",
        "victim_family_status",
        "victim_problem_economic",
        "victim_problem_health",
        "victim_problem_mind",
        "is_victim_problem_other",
      ],
    };
  },
  validations: {
    formData: {
      victim_first_name: {
        required,
      },
      victim_last_name: {
        required,
      },
      doer_relation: {
        required,
      },
      event_place: {
        required,
      },
    },
  },
  methods: {
    knowDoerTrigger(state) {
      this.formData.know_doer = state;
      if (!state) {
        this.formData.victim_first_name = "ไม่ทราบ";
        this.formData.victim_last_name = "ไม่ทราบ";
      } else {
        this.formData.victim_first_name = "";
        this.formData.victim_last_name = "";
      }
    },
    disabledBefore(date) {
      if (this.formData.event_date) {
        const dateList = this.formData.event_date.split("-");
        const today = new Date(
          dateList[2],
          parseInt(dateList[1]) - 1,
          dateList[0]
        );
        today.setHours(0, 0, 0, 0);
        return date < today || null;
      }
    },
    convertDate(property) {
      let christianYear = moment().format("YYYY");
      let buddhishYear = (parseInt(christianYear) + 543).toString();
      let thaiDate = moment()
        .format(
          "DD-MM-YYYY"
            .replace("YYYY", buddhishYear)
            .replace("YY", buddhishYear.substring(2, 4))
        )
        .replace(christianYear, buddhishYear);
      console.log(thaiDate);
      this.formData[property] = thaiDate;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? !$error : null;
    },
    setData(model) {
      if (model.name) {
        this.formData[model.name] = model.value;
      }
    },
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    save(nextPage) {
      // console.log(nextPage,'next')
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        Swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          confirmButtonText: "ตกลง",
        });
        return;
      }
      let all_false1 = false;
      let all_false2 = false;
      let all_false3 = false;
      let all_false4 = false;
      let isOtherVictim = false;
      let isOtherDoer = false;
      this.headThreeOne.forEach((x) => {
        if (x === "is_doer_problem_other" && this.formData[x]) {
          isOtherDoer = true;
        }
        if (this.formData[x] && !all_false1) {
          all_false1 = true;
        }
      });
      if (all_false1 && !isOtherDoer) {
        this.validateThreeOne.forEach((x) => {
          if (this.formData[x] && !all_false2) {
            all_false2 = true;
          }
        });
      } else {
        all_false2 = true;
      }
      this.headThreeTwo.forEach((x) => {
        if (x === "is_victim_problem_other" && this.formData[x]) {
          isOtherVictim = true;
        }
        if (this.formData[x] && !all_false3) {
          all_false3 = true;
        }
      });
      if (all_false3 && !isOtherVictim) {
        this.validateThreeTwo.forEach((x) => {
          if (this.formData[x] && !all_false4) {
            all_false4 = true;
          }
        });
      } else {
        all_false4 = true;
      }
      if (all_false1 && all_false2 && all_false3 && all_false4) {
        this.formData.event_date = moment(
          this.formData.event_date,
          "YYYY-MM-DD"
        )
          .add(543, "years")
          .format("YYYY-MM-DD");
        this.formData.hospital_date = moment(
          this.formData.hospital_date,
          "YYYY-MM-DD"
        )
          .add(543, "years")
          .format("YYYY-MM-DD");
        // this.formData.hospital_date = this.formData.hospital_date
        //   ? moment(this.formData.hospital_date, "DD-MM-YYYY").format(
        //       "YYYY-MM-DD"
        //     )
        //   : "";
        this.formData.recent_event_date = moment(
          this.formData.recent_event_date,
          "YYYY-MM-DD"
        )
          .add(543, "years")
          .format("YYYY-MM-DD");
        // this.formData.recent_event_date = this.formData.recent_event_date
        //   ? moment(this.formData.recent_event_date, "DD-MM-YYYY").format(
        //       "YYYY-MM-DD"
        //     )
        //   : "";
        this.formData.user_id = this.user ? this.user.id : "";
        Swal.fire({
          title: "กำลังบันทึกข้อมูล",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            this.$store
              .dispatch("Violence/saveForm2", this.formData)
              .then((response) => {
                Swal.close();
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ",
                    confirmButtonText: "ตกลง",
                    confirmButtonColor: "#4579DF",
                  }).then(() => {
                    if (nextPage) {
                      this.$router.push({
                        path: "violenec",
                        query: { page: 3, form: this.$route.query.form },
                      });
                    } else {
                      this.$router.push({
                        path: "violenec",
                        query: { page: 2, form: this.$route.query.form },
                      });
                      window.location.reload();
                    }
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "บันทึกไม่สำเร็จ",
                    confirmButtonText: "ตกลง",
                    confirmButtonColor: "#4579DF",
                  });
                }
              });
          },
        });
      } else if (!all_false1 || !all_false2) {
        Swal.fire({
          icon: "error",
          title:
            "กรุณากรอกข้อมูล ปัจจัยกระตุ้น(3.3) ผู้กระทำ อย่างน้อย 1 ตัวเลือก",
          confirmButtonText: "ตกลง",
        });
        return;
      } else if (!all_false3 || !all_false4) {
        Swal.fire({
          icon: "error",
          title:
            "กรุณากรอกข้อมูล ปัจจัยกระตุ้น(3.3) ผู้ถูกกระทำ อย่างน้อย 1 ตัวเลือก",
          confirmButtonText: "ตกลง",
        });
        return;
      }
    },
  },
};
</script>

<style>
</style>
