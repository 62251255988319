<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>1. ข้อมูลหน่วยรับบริการ</div>
          <div>หมายเลขเอกสาร : {{ formData.oscc_code }}</div>
        </div>
        <div class="card-body px-3 py-4 row">
          <div class="col-12">
            <label>หน่วยงานให้บริการ</label>
            <input
              readonly
              v-model="formData.service_name"
              class="form-control"
              type="text"
            />
          </div>
          <div class="col-12">
            <label>รหัสสถานบริการ</label>
            <input
              readonly
              v-model="formData.service_code"
              class="form-control"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5">
      <div class="card">
        <div
          class="
            card-header
            text-white
            bg-purple-2-theme
            d-flex
            justify-content-between
          "
        >
          <div>2. ข้อมูลผู้รับบริการ</div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3 required">
          2.1 ข้อมูลส่วนตัว
        </div>
        <div class="card-body px-3 py-4">
          <div class="row">
            <div class="col-4">
              <b-form-group id="oscc_code">
                <template #label>
                  รหัส OSCC<span class="star-red">*</span>
                </template>
                <b-form-input
                  id="oscc_code"
                  name="oscc_code"
                  readonly
                  v-model="$v.formData.oscc_code.$model"
                  :state="validateState('oscc_code')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกรหัส OSCC.</b-form-invalid-feedback
                >
              </b-form-group>

              <!-- <label>รหัส OSCC</label>
              <input
                v-model="formData.oscc_code"
                class="form-control "
                type="text"
              /> -->
            </div>
            <div class="col-4">
              <b-form-group id="oscc_no">
                <template #label>
                  เลขที่ผู้ป่วย (HN)<span class="star-red">*</span>
                </template>
                <b-form-input
                  id="oscc_no"
                  name="oscc_no"
                  :readonly="isAdmin"
                  v-model="$v.formData.oscc_no.$model"
                  :state="validateState('oscc_no')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณากรอกเลขที่ผู้ป่วย (HN)</b-form-invalid-feedback
                >
              </b-form-group>

              <!-- <label>เลขที่ผู้ป่วย (HN)</label>
              <input
                v-model="formData.oscc_no"
                class="form-control "
                type="text"
              /> -->
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label>สถานะบุคคล</label>
              <b-form-select
                class="form-control"
                :disabled="isAdmin"
                v-model="formData.identity_status"
                @change="setIdentityStatus($event)"
              >
                <b-form-select-option value="" disabled
                  >เลือกสถานะบุคคล</b-form-select-option
                >

                <b-form-select-option
                  v-for="(item, i) in personStatus"
                  :key="i"
                  :value="item.name"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-4">
              <label>{{ idLabel }}</label>
              <input
                :readonly="idShow || isAdmin"
                v-model="formData.id_number"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label>คำนำหน้าชื่อ</label>
              <b-form-select
                :disabled="isAdmin"
                @change="selectTitle"
                class="form-control"
                v-model="formData.title"
              >
                <b-form-select-option value="" disabled
                  >เลือกคำนำหน้าชื่อ</b-form-select-option
                >

                <b-form-select-option
                  v-for="(item, i) in titleList"
                  :key="i"
                  :value="item.name"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-4">
              <b-form-group id="first_name">
                <template #label>
                  ชื่อ<span class="star-red">*</span>
                </template>
                <b-form-input
                  :readonly="isAdmin"
                  id="first_name"
                  name="first_name"
                  v-model="$v.formData.first_name.$model"
                  :state="validateState('first_name')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณาระบุชื่อ.</b-form-invalid-feedback
                >
              </b-form-group>

              <!-- <label>ชื่อ</label>
              <input
                v-model="formData.first_name"
                class="form-control "
                type="text"
              /> -->
            </div>
            <div class="col-4">
              <b-form-group id="last_name">
                <template #label>
                  นามสกุล<span class="star-red">*</span>
                </template>
                <b-form-input
                  :readonly="isAdmin"
                  id="last_name"
                  name="last_name"
                  v-model="$v.formData.last_name.$model"
                  :state="validateState('last_name')"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณาระบุนาสกุล</b-form-invalid-feedback
                >
              </b-form-group>
              <!-- <input
                v-model="formData.last_name"
                class="form-control "
                type="text"
              /> -->
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="required">วัน/เดือน/ปีเกิด</label><br />
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    rounded
                    class=""
                    dense
                    v-model="computedDateFormatted"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.birth_date"
                  @change="genAge"
                  @input="menu = false"
                  locale="th-th"
                ></v-date-picker>
              </v-menu>
              <small v-if="!formData.birth_date" class="text-danger"
                >กรุณาระบุวัน/เดือน/ปีเกิด</small
              >
              <!-- <input
                v-model="formData.birth_date"
                class="form-control "
                type="text"
              /> -->
            </div>
            <div class="col-2">
              <b-form-group id="age">
                <template #label>
                  อายุ (ปี)<span class="star-red">*</span>
                </template>
                <b-form-input
                  id="age"
                  name="age"
                  readonly
                  v-model="$v.formData.age.$model"
                  :state="validateAge()"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >กรุณาระบุอายุ.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div class="col-2">
              <b-form-group id="month">
                <template #label>
                  เดือน<span class="star-red">*</span>
                </template>
                <b-form-input
                  id="month"
                  name="month"
                  readonly
                  v-model="formData.age_month"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <label>เพศ</label>
              <RadioGroup
                pattern="violence"
                :setData="formData.sex"
                @clickAnswer="formData.sex = $event"
                :choice="sex"
              ></RadioGroup>
            </div>
            <div class="col-12">
              <label>ศาสนา</label>
              <RadioGroup
                pattern="violence"
                :setData="formData.religion"
                @clickAnswer="formData.religion = $event"
                :choice="religion"
              ></RadioGroup>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label-for="nationality">
                <template #label>
                  สัญชาติ<span class="star-red">*</span>
                </template>
                <b-form-select
                  id="nationality"
                  :state="validateState('nationality')"
                  :disabled="isAdmin"
                  class="form-control"
                  @change="setNationality($event)"
                  v-model="$v.formData.nationality.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกสัญชาติ</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in nationality"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกสัญชาติ</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div v-if="isOtherNationality" class="col-4">
              <label>โปรดระบุ(สัญชาติ อื่นๆ)</label>
              <input
                v-model="formData.nationality_other"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label-for="welfare">
                <template #label>
                  สิทธิการรักษาพยาบาล (ติดตัว)<span class="star-red">*</span>
                </template>
                <b-form-select
                  id="welfare"
                  :disabled="isAdmin"
                  :state="validateState('welfare')"
                  class="form-control"
                  v-model="$v.formData.welfare.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกสิทธิการรักษาพยาบาล</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in welfare"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกสิทธิการรักษาพยาบาล</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label-for="welfare_now">
                <template #label>
                  สิทธิการรักษาพยาบาล (ครั้งนี้)<span class="star-red">*</span>
                </template>
                <b-form-select
                  :disabled="isAdmin"
                  id="welfare_now"
                  :state="validateState('welfare_now')"
                  class="form-control"
                  v-model="$v.formData.welfare_now.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกสิทธิการรักษาพยาบาล</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in welfareNow"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกสิทธิการรักษาพยาบาล
                  (ครั้งนี้)</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label-for="education">
                <template #label>
                  ระดับการศึกษา<span class="star-red">*</span>
                </template>
                <b-form-select
                  :disabled="isAdmin"
                  id="education"
                  :state="validateState('education')"
                  class="form-control"
                  v-model="$v.formData.education.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกระดับการศึกษา</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in education"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกระดับการศึกษา</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label-for="occupation">
                <template #label>
                  อาชีพ<span class="star-red">*</span>
                </template>
                <b-form-select
                  :disabled="isAdmin"
                  id="occupation"
                  :state="validateState('occupation')"
                  class="form-control"
                  v-model="$v.formData.occupation.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกอาชีพ</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in occupation"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกอาชีพ</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label-for="marriage_status">
                <template #label>
                  สถานภาพสมรส<span class="star-red">*</span>
                </template>
                <b-form-select
                  :disabled="isAdmin"
                  id="marriage_status"
                  :state="validateState('marriage_status')"
                  class="form-control"
                  v-model="$v.formData.marriage_status.$model"
                >
                  <b-form-select-option value="" disabled
                    >เลือกสถานภาพสมรส</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in marriageStatus"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกเลือกสถานภาพสมรส</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          2.2 ที่อยู่ตามทะเบียนบ้าน
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <label class="required">บ้านเลขที่</label>
              <b-form-input
                id="house_number"
                name="house_number"
                :readonly="isAdmin"
                :state="validateState('house_number')"
                v-model="$v.formData.house_number.$model"
              ></b-form-input>
              <b-form-invalid-feedback id="input-2-live-feedback"
                >กรุณาระบุบ้านเลขที่</b-form-invalid-feedback
              >
            </div>
            <div class="col-4">
              <label>หมู่เลขที่</label>
              <input
                :readonly="isAdmin"
                v-model="formData.moo"
                class="form-control"
                type="text"
              />
            </div>
            <div class="col-4">
              <label class="required">ถนน/ซอย</label>
              <b-form-input
                id="road"
                :readonly="isAdmin"
                :state="validateState('road')"
                v-model="$v.formData.road.$model"
                class="form-control"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback id="input-2-live-feedback"
                >กรุณาระบุถนน</b-form-invalid-feedback
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label class="required">จังหวัด</label>
              <b-form-select
                id="province"
                :disabled="isAdmin"
                :state="validateState('province')"
                v-model="$v.formData.province.$model"
                @change="setDistrict($event)"
                class="form-control"
              >
                <b-form-select-option value="" disabled
                  >เลือกจังหวัด</b-form-select-option
                >

                <b-form-select-option
                  v-for="(item, i) in province"
                  :key="i"
                  :value="item.code"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
              <b-form-invalid-feedback id="input-2-live-feedback"
                >กรุณาเลือกจังหวัด</b-form-invalid-feedback
              >
            </div>
            <div class="col-4">
              <label class="required">เขต/อำเภอ</label>
              <b-form-select
                id="district"
                :disabled="isAdmin"
                :state="validateState('district')"
                v-model="$v.formData.district.$model"
                class="form-control"
                @change="setSubDistrict($event)"
              >
                <b-form-select-option value="" disabled
                  >เลือกเขต/อำเภอ</b-form-select-option
                >

                <b-form-select-option
                  v-for="(item, i) in district"
                  :key="i"
                  :value="item.district_id"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
              <b-form-invalid-feedback id="input-2-live-feedback"
                >กรุณาเลือกเขต/อำเภอ</b-form-invalid-feedback
              >
            </div>
            <div class="col-4">
              <label class="required">แขวง/ตำบล</label>
              <b-form-select
                :disabled="isAdmin"
                id="sub_district"
                class="form-control"
                :state="validateState('sub_district')"
                v-model="$v.formData.sub_district.$model"
              >
                <b-form-select-option value="" disabled
                  >เลือกแขวง/ตำบล</b-form-select-option
                >

                <b-form-select-option
                  v-for="(item, i) in subDistrict"
                  :key="i"
                  :value="item.name"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
              <b-form-invalid-feedback id="input-2-live-feedback"
                >กรุณาเลือกแขวง/ตำบล</b-form-invalid-feedback
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label-for="input15" class="mb-0">
                <template #label> เบอร์โทรศัพท์บ้าน </template>
                <b-form-input
                  id="input15"
                  :readonly="isAdmin"
                  v-model="formData.house_phone"
                  v-on:keypress="NumbersOnly"
                  class="input-form-oscc"
                  placeholder="ระบุเบอร์โทรศัพท์บ้าน"
                  :maxlength="10"
                  type="tel"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label-for="input16" class="mb-0">
                <template #label> เบอร์โทรศัพท์ <span style="color: red">*</span></template>
                <b-form-input
                  :readonly="isAdmin"
                  v-model="formData.mobile_phone"
                  id="input16"
                  v-on:keypress="NumbersOnly"
                  class="input-form-oscc"
                  placeholder="ระบุเบอร์โทรศัพท์"
                  :maxlength="10"
                  type="tel"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาระบุเบอร์โทรศัพท์</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3">
          2.3 ที่อยู่ปัจจุบันที่สามารถติดต่อได้
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <RadioGroup
                pattern="violence"
                :setData="formData.is_other_contact_address"
                @clickAnswer="formData.is_other_contact_address = $event"
                :choice="isAddress"
              ></RadioGroup>
            </div>
          </div>
          <div v-if="formData.is_other_contact_address">
            <div class="row">
              <div class="col-4">
                <label class="required">บ้านเลขที่</label>
                <b-form-input
                  :readonly="isAdmin"
                  id="house_number_other"
                  name="house_number_other"
                  v-model="formData.house_number_other"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาระบุบ้านเลขที่</b-form-invalid-feedback
                >
              </div>
              <div class="col-4">
                <label>หมู่เลขที่</label>
                <input
                  :readonly="isAdmin"
                  v-model="formData.moo_other"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-4">
                <label class="required">ถนน/ซอย</label>
                <b-form-input
                  :readonly="isAdmin"
                  id="road_other"
                  v-model="formData.road_other"
                  class="form-control"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาระบุถนน</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="required">จังหวัด</label>
                <b-form-select
                  :disabled="isAdmin"
                  id="province_other"
                  v-model="formData.province_other"
                  @change="setDistrictOther($event)"
                  class="form-control"
                >
                  <b-form-select-option value="" disabled
                    >เลือกจังหวัด</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in provinceOther"
                    :key="i"
                    :value="item.code"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกจังหวัด</b-form-invalid-feedback
                >
              </div>
              <div class="col-4">
                <label class="required">เขต/อำเภอ</label>
                <b-form-select
                  :disabled="isAdmin"
                  v-model="formData.district_other"
                  @change="setSubDistrictOther($event)"
                  class="form-control"
                >
                  <b-form-select-option value="" disabled
                    >เลือกเขต/อำเภอ</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in districtOther"
                    :key="i"
                    :value="item.district_id"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกเขต/อำเภอ</b-form-invalid-feedback
                >
              </div>
              <div class="col-4">
                <label class="required">แขวง/ตำบล</label>
                <b-form-select
                  :disabled="isAdmin"
                  class="form-control"
                  v-model="formData.sub_district_other"
                >
                  <b-form-select-option value="" disabled
                    >เลือกแขวง/ตำบล</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in subDistrictOther"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >กรุณาเลือกแขวง/ตำบล</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>เบอร์โทรศัพท์บ้าน</label>
                <input
                  :readonly="isAdmin"
                  v-model="formData.house_phone_other"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-4">
                <b-form-group label-for="input16" class="mb-0">
                  <template #label> เบอร์โทรศัพท์ </template>
                  <b-form-input
                    :readonly="isAdmin"
                    v-model="formData.mobile_phone_other"
                    id="input16"
                    v-on:keypress="NumbersOnly"
                    class="input-form-oscc"
                    placeholder="ระบุเบอร์โทรศัพท์"
                    :maxlength="10"
                    type="tel"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-2-live-feedback"
                    >กรุณาระบุเบอร์โทรศัพท์</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-purple-3-theme mt-3 py-2 px-3 required">
          2.4 ข้อมูลบุคคลผู้นำส่ง
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <RadioGroup
                pattern="violence"
                :setData="formData.is_reference_person"
                @clickAnswer="formData.is_reference_person = $event"
                :choice="isBool"
              ></RadioGroup>
            </div>
          </div>
          <div v-if="formData.is_reference_person">
            <div class="row">
              <div class="col-4">
                <b-form-group label-for="reference_person_name">
                  <template #label> ชื่อ </template>
                  <b-form-input
                    :readonly="isAdmin"
                    id="reference_person_name"
                    class="input-form-oscc"
                    v-model="formData.reference_person_name"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-4">
                <label>นามสกุล</label>
                <input
                  :readonly="isAdmin"
                  v-model="formData.reference_person_surname"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-4">
                <label>ความเกี่ยวข้อง</label>
                <b-form-select
                  :disabled="isAdmin"
                  class="form-control"
                  v-model="formData.reference_person_relation"
                >
                  <b-form-select-option value="" disabled
                    >เลือกความเกี่ยวข้อง</b-form-select-option
                  >

                  <b-form-select-option
                    v-for="(item, i) in relationStatus"
                    :key="i"
                    :value="item.name"
                    >{{ item.name }}</b-form-select-option
                  >
                </b-form-select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>เบอร์โทรศัพท์</label>
                <input
                  :readonly="isAdmin"
                  v-model="formData.reference_person_phone"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row mt-4">
      <div class="col-3">
        <button @click="goto('result')" class="btn btn-cancel-outline">
          ยกเลิก
        </button>
      </div>
      <div class="col-3 offset-3">
        <button
          :disabled="isAdmin"
          @click="saveForm(false)"
          class="btn btn-save-outline"
        >
          บันทึก
        </button>
      </div>
      <div class="col-3">
        <button
          :disabled="isAdmin"
          @click="saveForm(true)"
          class="btn btn-next-outline"
        >
          ถัดไป
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioGroup from "../RadioGroup.vue";
import moment from "moment";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: {
    RadioGroup,
    //DatePicker,
  },
  async created() {
    this.formData.service_code = this.user ? this.user.agency_code : "";
    this.formData.service_name = this.user ? this.user.agency_name : "";
    await this.$store.dispatch("MasterData/setForm1");
    Swal.fire({
      title: "กำลังเรียกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        await this.getOSCC();
        await this.getNoneIdNumber();
        await this.$store.dispatch("MasterData/setProvince");
        if (this.$route.query.form) {
          await this.$store
            .dispatch("Violence/showForm1", this.$route.query.form)
            .then((response) => {
              if (response) {
                this.formData = response.form;
                this.mainForm = response.main_form;
                let refer = response.refer;
                this.isAdmin = this.user ? this.user.role == 1 : false;
                let isRefer = false;
                if (refer) {
                  if (this.user.agency_code == refer.from && refer.status == 0)
                    isRefer = false;
                  else if (
                    this.user.agency_code == refer.from &&
                    refer.status == 1
                  )
                    isRefer = true;
                  else if (
                    this.user.agency_code == refer.to &&
                    refer.status == 0
                  )
                    isRefer = true;
                  else if (
                    this.user.agency_code == refer.to &&
                    refer.status == 1
                  )
                    isRefer = false;
                }
                if (!this.isAdmin) this.isAdmin = isRefer;
                this.setArea();
                this.setNationality(response.form.nationality);
                this.formData.birth_date = moment(
                  response.form.birth_date,
                  "YYYY-MM-DD"
                )
                  .subtract(543, "years")
                  .format("YYYY-MM-DD");
              } else {
                this.convertDate();
              }
            });
        } else {
          this.convertDate();
        }
        Swal.clickConfirm();
      },
    });
  },
  computed: {
    computedDateFormatted() {
      if (!this.formData.birth_date) return null;
      const [year, month, day] = this.formData.birth_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    isIDCardValid: function () {
      let sum = 0;
      if (this.register.id_card.length != 13) return false;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(this.register.id_card.charAt(i)) * (13 - i);
      }
      let mod = sum % 11;
      let check = (11 - mod) % 10;
      if (check == parseInt(this.register.id_card.charAt(12))) {
        return true;
      }
      return false;
    },
    ...mapState({
      nationality: (state) => {
        return state.MasterData.form1.nationality;
      },
      welfare: (state) => {
        return state.MasterData.form1.welfare;
      },
      welfareNow: (state) => {
        return state.MasterData.form1.welfareNow;
      },
      education: (state) => {
        return state.MasterData.form1.education;
      },
      occupation: (state) => {
        return state.MasterData.form1.occupation;
      },
      marriageStatus: (state) => {
        return state.MasterData.form1.marriageStatus;
      },
      relationStatus: (state) => {
        return state.MasterData.form1.relationStatus;
      },
      province: (state) => {
        return state.MasterData.province;
      },
      district: (state) => {
        return state.MasterData.district;
      },
      subDistrict: (state) => {
        return state.MasterData.subDistrict;
      },
      provinceOther: (state) => {
        return state.MasterData.provinceOther;
      },
      districtOther: (state) => {
        return state.MasterData.districtOther;
      },
      subDistrictOther: (state) => {
        return state.MasterData.subDistrictOther;
      },
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  data() {
    return {
      mainForm: null,
      menu: false,
      sameAddress: true,
      none_id_number: 0,
      idLabel: "เลขบัตรประจำตัวประชาชน",
      idShow: true,
      noneId: "",
      isOtherNationality: false,
      isAdmin: false,
      sex: [
        { name: "ชาย", grid: "col-2" },
        { name: "หญิง", grid: "col-2" },
        { name: "เพศทางเลือก", grid: "col-5" },
      ],
      titleList: [
        { name: "นาย" },
        { name: "นาง" },
        { name: "นางสาว" },
        { name: "ดช." },
        { name: "ดญ." },
      ],
      religion: [
        { name: "พุทธ", grid: "col-2" },
        { name: "อิสลาม", grid: "col-2" },
        { name: "คริสต์", grid: "col-2" },
        { name: "ฮินดู", grid: "col-2" },
      ],
      isAddress: [
        { name: "ที่อยู่อื่น", value: true, grid: "col-4" },
        { name: "ที่อยู่ตามทะเบียนบ้าน", value: false, grid: "col-4" },
      ],
      isBool: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      personStatus: [
        { name: "บัตรประชาชน", isShow: true },
        { name: "บัตรต่างชาติ หรือ พาสปอร์ต", isShow: true },
        { name: "ไม่มี", isShow: false },
      ],
      formData: {
        user_id: "",
        none_id_number: 0,
        identity_status: "",
        id_number: "",
        title: "",
        oscc_no: "",
        oscc_code: "",
        service_name: "",
        service_code: "",
        first_name: "",
        last_name: "",
        birth_date: "",
        age: 0,
        age_month: 0,
        sex: "ชาย",
        religion: "พุทธ",
        nationality: "",
        nationality_other: "",
        welfare: "",
        welfare_now: "",
        education: "",
        occupation: "",
        marriage_status: "",
        house_number: "",
        moo: "",
        road: "",
        province: "",
        district: "",
        sub_district: "",
        house_phone: "",
        mobile_phone: "",
        is_other_contact_address: false,
        house_number_other: "",
        moo_other: "",
        oscc_count: "",
        road_other: "",
        province_other: "",
        district_other: "",
        sub_district_other: "",
        house_phone_other: "",
        mobile_phone_other: "",
        is_reference_person: false,
        reference_person_name: "",
        reference_person_surname: "",
        reference_person_relation: "",
        reference_person_phone: "",
      },
    };
  },
  validations: {
    formData: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      age: {
        required,
      },
      nationality: {
        required,
      },
      welfare: {
        required,
      },
      welfare_now: {
        required,
      },
      education: {
        required,
      },
      occupation: {
        required,
      },
      marriage_status: {
        required,
      },
      oscc_no: {
        required,
      },
      oscc_code: {
        required,
      },
      house_number: {
        required,
      },
      road: {
        required,
      },
      province: {
        required,
      },
      district: {
        required,
      },
      sub_district: {
        required,
      },
        mobile_phone:{
          required
        }
    },
  },

  methods: {
    setArea() {
      if (this.formData.province)
        this.$store.dispatch("MasterData/setDistrict", this.formData.province);
      if (this.formData.district)
        this.$store.dispatch(
          "MasterData/setSubDistrict",
          this.formData.district
        );
      if (this.formData.province_other)
        this.$store.dispatch(
          "MasterData/setDistrictOther",
          this.formData.province_other
        );
      if (this.formData.district_other)
        this.$store.dispatch(
          "MasterData/setSubDistrictOther",
          this.formData.district_other
        );
    },
    selectTitle(event) {
      if (event === "นาย") {
        this.formData.sex = "ชาย";
      } else {
        this.formData.sex = "หญิง";
      }
    },
    async getOSCC() {
      await this.$store.dispatch("Form/getOsccCode").then((response) => {
        if (response) {
          const osccCount = response;
          let christianYear = moment().format("YYYY");
          let buddhishYear = (parseInt(christianYear) + 543).toString();
          this.formData.oscc_code = `${buddhishYear}-00000${osccCount}`;
          this.formData.oscc_count = osccCount;
        }
      });
    },
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
    async getNoneIdNumber() {
      await this.$store.dispatch("Form/getNoneId").then((response) => {
        const formNumber = 11;
        let noneId = "P";
        if (response) {
          this.none_id_number = response;
          for (let i = 0; i <= formNumber; i++) {
            if (formNumber == i) {
              noneId += response;
            } else {
              noneId += "0";
            }
          }
        }
        this.noneId = noneId;
      });
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    genAge(event) {
        const startYear = moment(event, "YYYY-MM-DD");
        const currentYear = moment();
        let monthDiff = currentYear.diff(startYear, "months");
        const year = Math.trunc(monthDiff / 12);
        const month = year * 12 - monthDiff;
        this.formData.age = year;
        this.formData.age_month = Math.abs(month);
    },
    convertDate() {
      // let christianYear = moment().format("YYYY");
      // let buddhishYear = (parseInt(christianYear) + 543).toString();
      // let thaiDate = moment()
      //   .format(
      //     "DD-MM-YYYY"
      //       .replace("YYYY", buddhishYear)
      //       .replace("YY", buddhishYear.substring(2, 4))
      //   )
      //   .replace(christianYear, buddhishYear);
      // this.formData.birth_date = thaiDate;
    },
    validateStateOther(name) {
      if (this.formData.is_other_contact_address) {
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      } else {
        return true;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? !$error : null;
    },
    validateAge() {
      if (this.formData.age <= 0 && this.formData.age_month <= 0) return false;
      else true;
    },
    setDistrictOther(model) {
      this.$store.dispatch("MasterData/setDistrictOther", model);
    },
    setSubDistrictOther(model) {
      this.$store.dispatch("MasterData/setSubDistrictOther", model);
    },
    setDistrict(model) {
      this.$store.dispatch("MasterData/setDistrict", model);
    },
    setSubDistrict(model) {
      this.$store.dispatch("MasterData/setSubDistrict", model);
    },
    setForm() {
      console.log(this.formData);
    },
    saveForm(nextPage) {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        Swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          confirmButtonText: "ตกลง",
        });
        return;
      }
      if (this.formData.nationality === "อื่นๆ") {
        if (!this.formData.nationality_other) {
          Swal.fire({
            icon: "error",
            title: "กรุณากรอกข้อมูลสัญชาติอื่นๆ",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }
      if (this.formData.is_other_contact_address) {
        if (
          !this.formData.house_number_other ||
          !this.formData.road_other ||
          !this.formData.province_other ||
          !this.formData.district_other ||
          !this.formData.sub_district_other
        ) {
          Swal.fire({
            icon: "error",
            title: "กรุณากรอกที่อยู่อื่นให้ครบถ้วน",
            confirmButtonText: "ตกลง",
          });
          return;
        }
      }

      this.formData.user_id = this.user ? this.user.id : "";
      if (moment(this.formData.birth_date, "YYYY-MM-DD").format("YYYY") < moment().format('YYYY')){
        this.formData.birth_date = moment(this.formData.birth_date, "YYYY-MM-DD")
          .add(543, "years")
          .format("YYYY-MM-DD");
      }else{
        this.formData.birth_date = moment(this.formData.birth_date, "YYYY-MM-DD")
            .format("YYYY-MM-DD");
      }

      Swal.fire({
        title: "กำลังบันทึกข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          this.$store
            .dispatch("Violence/saveForm", this.formData)
            .then((response) => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                }).then(() => {
                  if (nextPage) {
                    this.$router.push({
                      path: "violenec",
                      query: { page: 2, form: response.data.form_id },
                    });
                  } else {
                    this.$router.push({
                      path: "violenec",
                      query: { page: 1, form: response.data.form_id },
                    });
                    window.location.reload();
                  }
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "บันทึกไม่สำเร็จ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#4579DF",
                });
              }
            });
        },
      });
    },
    setIdentityStatus(model) {
      switch (model) {
        case "บัตรประชาชน":
          this.idLabel = "เลขบัตรประจำตัวประชาชน";
          this.formData.id_number = "";
          this.formData.none_id_number = "";
          this.idShow = false;
          break;
        case "บัตรต่างชาติ หรือ พาสปอร์ต":
          this.idLabel = "เลขบัตรต่างชาติ หรือ พาสปอร์ต";
          this.formData.id_number = "";
          this.formData.none_id_number = "";
          this.idShow = false;
          break;
        case "ไม่มี":
          this.idLabel = "เลขประจำตัว";
          this.formData.id_number = this.noneId;
          this.formData.none_id_number = this.none_id_number;
          this.idShow = true;
          break;
      }
    },
    setNationality(model) {
      if (model === "อื่นๆ") this.isOtherNationality = true;
      else this.isOtherNationality = false;
    },
  },
};
</script>

<style>
</style>
